import { ParticipantActionTypes } from "./../ActionTypes/ParticipantActionTypes";
import { ParticipantState } from "../StateTypes/StateTypes";
import {
  ADD_PARTICIPANT,
  REMOVE_PARTICIPANT,
  UPDATE_PARTICIPANT,
  SET_LOCAL_PARTICIPANT,
  CLEAR_PARTICIPANTS,
  SET_SCREEN_SHARING_PARTICIPANT,
  REMOVE_SCREEN_SHARING_PARTICIPANT,
  ADD_PARTICIPANT_IN_WAITING_LOBBY,
  REMOVE_PARTICIPANT_FROM_WAITING_LOBBY,
} from "../ReduxConstants";

// Define the initial state for the ProConf reducer
const initialState: ParticipantState = {
  localParticipant: null,
  participants: [],
  screenSharingParticipants: [],
  waitingLobby: [],
  showWaitingLobbyPopup: false,
  newParticipantInLobby: {},
};

// Reducer function to handle state changes based on actions
const ParticipantReducer = (
  state = initialState,
  action: ParticipantActionTypes
): ParticipantState => {
  switch (action.type) {
    case ADD_PARTICIPANT:
      return {
        ...state,
        participants: [...state.participants, action.payload],
      };
    case REMOVE_PARTICIPANT:
      return {
        ...state,
        participants: state.participants.filter(
          (p) => p?.sid !== action.payload
        ),
      };
    case UPDATE_PARTICIPANT:
      return {
        ...state,
        participants: state.participants.map((p) =>
          p.sid === action.payload.sid ? { ...p, ...action.payload } : p
        ),
        localParticipant:
          action.payload?.sid === state.localParticipant?.sid
            ? { ...state.localParticipant, ...action.payload }
            : state.localParticipant,
      };
    case SET_LOCAL_PARTICIPANT:
      return { ...state, localParticipant: action.payload };

    case SET_SCREEN_SHARING_PARTICIPANT:
      return {
        ...state,
        screenSharingParticipants: [
          ...state.screenSharingParticipants,
          { ...action.payload, isScreenShareParticipant: true },
        ],
      };

    case REMOVE_SCREEN_SHARING_PARTICIPANT:
      return {
        ...state,
        screenSharingParticipants: state.screenSharingParticipants.filter(
          (p) => p?.sid !== action.payload
        ),
      };

    case ADD_PARTICIPANT_IN_WAITING_LOBBY:
      return {
        ...state,
        waitingLobby: [...state.waitingLobby, action.payload],
      };
    case REMOVE_PARTICIPANT_FROM_WAITING_LOBBY:
      let waitingLobby = [...state.waitingLobby];
      let index = waitingLobby.findIndex((singleParticipant) => {
        return (
          action.payload.memberId === singleParticipant.memberId &&
          action.payload.memberName === undefined
        );
      });
      index !== -1 && waitingLobby?.splice(index, 1);
      return {
        ...state,
        waitingLobby,
      };
    case CLEAR_PARTICIPANTS:
      return {
        localParticipant: null,
        participants: [],
        screenSharingParticipants: [],
        waitingLobby: [],
        showWaitingLobbyPopup: false,
        newParticipantInLobby: {},
      };
    default:
      return state;
  }
};

export default ParticipantReducer;
