import React, { useState } from "react";
import { Input } from "antd";
import { DotLottiePlayer } from "@dotlottie/react-player";

//Translation
import { getTranslation } from "../../Resources/Localization/i18n";

//Assets
import proconfLogo from "../../Resources/Images/PLogo.png";
import error from "../../Resources/Images/icerror.svg";

//Style
import "./Login.scss";

interface LoginProps {
  onLoginClick: (email: string, password: string) => void; // Define the type of onLoginClick in the props interface
  loginApiErrorMsg: string;
  setLoginApiErrorMsg: (error: string) => void;
  onJoinClick: (username: string, roomName: string) => void;
  joinApiErrorMsg: string;
  setJoinApiErrorMsg: (error: string) => void;
  roomnameFromURL: string;
}

/**
 *
 * @param onLoginClick function to handle on click of the login button
 * @returns jsx to render the login form
 */
const Login: React.FC<LoginProps> = ({
  onLoginClick,
  loginApiErrorMsg,
  setLoginApiErrorMsg,
  onJoinClick,
  joinApiErrorMsg,
  setJoinApiErrorMsg,
  roomnameFromURL,
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [yourName, setYourName] = useState("");
  
  const isGuestLogin = roomnameFromURL ? true : false;

  /* #region Event Handlers */
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    let value = e.target.value;
    setLoginApiErrorMsg("");
    setJoinApiErrorMsg("");
    switch (type) {
      case "email":
        setEmail(value);
        break;
      case "password":
        setPassword(value);
        break;
      case "yourName":
        setYourName(value);
        break;
    }
  };

  const onLogin = () => onLoginClick(email, password);

  const onGuestJoinClick = () => onJoinClick(yourName, roomnameFromURL);

  const handleEnterKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    // Check if the pressed key is Enter (key code 13)
    if (event.key === "Enter") {
      // Programmatically click the button
      onLoginClick(email, password);
    }
  };

  const handleEnterKeyPressForJoin = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    // Check if the pressed key is Enter (key code 13)
    if (event.key === "Enter") {
      // Programmatically click the button
      onJoinClick(yourName, roomnameFromURL);
    }
  };
  /* #endregion */

  /* #region Render Methods */
  /**
   *
   * @returns jsx for login form fields
   */
  const renderLoginFormFields = () => {
    return (
      <div className="fields">
        <div className="inputfield">
          <div className="label">{getTranslation("email")}</div>
          <Input
            className="inputfield-inputpassword"
            size="large"
            onChange={(e) => handleInputChange(e, "email")}
            name="email"
            placeholder={getTranslation("emailPlaceholder")}
            type="text"
            required
            maxLength={100}
            value={email || ""}
          />
        </div>
        <div className="inputfield">
          <div className="label">{getTranslation("password")}</div>
          <Input.Password
            className="inputfield-inputpassword"
            size="large"
            placeholder={getTranslation("passwordPlaceholder")}
            onChange={(e) => handleInputChange(e, "password")}
            name="password"
            type="text"
            required
            maxLength={100}
            value={password || ""}
            onKeyDown={handleEnterKeyPress}
          />
        </div>
      </div>
    );
  };

  /**
   *
   * @returns jsx for the buttons section
   */
  const renderLoginButtons = () => {
    return (
      <div className="actionbuttons">
        <div className="loginframe">
          <button
            className="buttonprimary"
            onClick={onLogin}
            disabled={!email.trim() || !password.trim()}
          >
            <b className="buttonlabel">{getTranslation("LOGIN")}</b>
          </button>
          {loginApiErrorMsg && (
            <div className="error2">
              <img className="icerror-icon" alt="" src={error} />
              <div className="errormessage">{loginApiErrorMsg}</div>
            </div>
          )}
        </div>
      </div>
    );
  };

  /**
   *
   * @returns jsx for Join As Guest form fields
   */
  const renderJoinAsGuestFormFields = () => {
    return (
      <div className="fields">
        <div className="inputfield">
          <div className="label">{getTranslation("yourName")}</div>
          <Input
            className="inputfield-inputpassword"
            size="large"
            onChange={(e) => handleInputChange(e, "yourName")}
            name="yourName"
            placeholder={getTranslation("enterYourName")}
            type="text"
            required
            maxLength={50}
            value={yourName || ""}
          />
        </div>
        <div className="inputfield">
          <div className="label">{getTranslation("roomName")}</div>
          <Input
            className="inputfield-inputpassword"
            size="large"
            placeholder={getTranslation("enterRoomName")}
            onChange={(e) => handleInputChange(e, "roomName")}
            name="roomName"
            type="text"
            required
            maxLength={50}
            value={roomnameFromURL || ""}
            onKeyDown={handleEnterKeyPressForJoin}
            disabled={roomnameFromURL ? true : false}
          />
        </div>
      </div>
    );
  };

  /**
   *
   * @returns jsx for the buttons section
   */
  const renderJoinAsGuestButtons = () => {
    return (
      <div className="actionbuttons">
        <div className="loginframe">
          <button
            className="buttonprimary"
            onClick={onGuestJoinClick}
            disabled={!yourName.trim() || !roomnameFromURL.trim()}
          >
            <b className="buttonlabel">{getTranslation("join")}</b>
          </button>
          {joinApiErrorMsg && (
            <div className="error2">
              <img className="icerror-icon" alt="" src={error} />
              <div className="errormessage">{joinApiErrorMsg}</div>
            </div>
          )}
        </div>
      </div>
    );
  };
  /* #endregion */

  return (
    <div className="login">
      <div className="content">
        <div className="tex-box">
          <div className="loginlabel">
            <b className="logintext">
              {isGuestLogin
                ? getTranslation("joinAsGuest")
                : getTranslation("login")}
            </b>
          </div>
          {isGuestLogin ? (
            <>
              {renderJoinAsGuestFormFields()}
              {renderJoinAsGuestButtons()}
            </>
          ) : (
            <>
              {renderLoginFormFields()}
              {renderLoginButtons()}
            </>
          )}
        </div>
        <div className="logoillustration">
          <img className="logo-icon" alt="" src={proconfLogo} />
          <DotLottiePlayer
            className="illustration"
            autoplay
            src="/LoginPage.json"
            loop
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
