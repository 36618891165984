import { FunctionComponent, useState, useRef, useCallback } from "react";

import "./InCallParticipant.scss";
import MeetingParticipantInfo from "../MeetingParticipantInfo/MeetingParticipantInfo";
import PortalPopup from "../PortalPopup/PortalPopup";
import ParticipantActionMenu from "../ParticipantActionMenu/ParticipantActionMenu";
import { CONFIGURATIONS } from "../../Constants/AppConstants";

import { ReactComponent as MicOn } from "../../Resources/InCallImages/ic_mic_off1.svg";
import { ReactComponent as MicOff } from "../../Resources/InCallImages/ic_mic_off.svg";
import { ReactComponent as VideoOn } from "../../Resources/InCallImages/ic_mic_off3.svg";
import { ReactComponent as VideoOff } from "../../Resources/InCallImages/ic_mic_off2.svg";
import MoreIcon from "../../Resources/InCallImages/ic_mic_off4.svg";
import { useSelector } from "react-redux";
import { Participant } from "proconf-web-sdk";

export type InCallParticipantType = {
  className?: string;
  showMicStatus?: boolean;
  showVideoStatus?: boolean;
  propMinWidth?: string;
  propMinHeight?: string;
  propBorder?: string;
  initialsText?: string;
  propFontSize?: string;
  initialsText1?: string;
  participantName: string;
  participantRole: string;
  participant: Participant;
};

const InCallParticipant: FunctionComponent<InCallParticipantType> = ({
  className = "",
  showMicStatus = true,
  showVideoStatus = true,
  propMinWidth,
  propMinHeight,
  propBorder,
  initialsText,
  propFontSize,
  initialsText1,
  participantName,
  participantRole,
  participant,
}) => {
  const threeDotButtonRef = useRef<HTMLButtonElement>(null);
  const [isParticipantActionMenuPopupOpen, setParticipantActionMenuPopupOpen] =
    useState(false);

  const openParticipantActionMenuPopup = useCallback(() => {
    setParticipantActionMenuPopupOpen(true);
  }, []);

  const closeParticipantActionMenuPopup = useCallback(() => {
    setParticipantActionMenuPopupOpen(false);
  }, []);

  const { configurations } = useSelector((state: any) => state.LoginReducer);
  const localParticipant = useSelector(
    (state: any) => state.ParticipantReducer?.localParticipant
  );
  console.log(participantName, participantRole, showMicStatus, showVideoStatus);

  return (
    <>
      <div className={`incallparticipant ${className}`}>
        <MeetingParticipantInfo
          participantName={participantName}
          participantSubtitle={participantRole}
        />
        {/* Hide moderator controls */}
        <div className="callactioncontrols">
          {showMicStatus ? <MicOn /> : <MicOff />}
          {CONFIGURATIONS.VIDEO_CALL in configurations &&
            (showVideoStatus ? <VideoOn /> : <VideoOff />)}
          {CONFIGURATIONS.MODERATOR_CONTROL in configurations &&
            localParticipant?.isModerator &&
            !participant?.isLocal && (
              <button
                className="threedotbutton"
                ref={threeDotButtonRef}
                onClick={openParticipantActionMenuPopup}
              >
                <img
                  className="mediastatusicon"
                  alt="mic off icon"
                  src={MoreIcon}
                />
              </button>
            )}
        </div>
      </div>
      {isParticipantActionMenuPopupOpen && (
        <PortalPopup
          placement="Top right"
          relativeLayerRef={threeDotButtonRef}
          onOutsideClick={closeParticipantActionMenuPopup}
        >
          <ParticipantActionMenu
            participant={participant}
            onClose={closeParticipantActionMenuPopup}
          />
        </PortalPopup>
      )}
    </>
  );
};

export default InCallParticipant;
