import { FunctionComponent, useState } from "react";
import "./ParticipantsPanel.scss";
import PanelTitleBar from "../PanelTitleBar/PanelTitleBar";
import PrimaryButtonSmall from "../PrimaryButtonSmall/PrimaryButtonSmall";
import SecondaryButtonSmall from "../SecondaryButtonSmall/SecondaryButtonSmall";
import InCallParticipant from "../InCallParticipant/InCallParticipant";
import { getTranslation } from "../../Resources/Localization/i18n";
import { useSelector } from "react-redux";
import { CONFIGURATIONS } from "../../Constants/AppConstants";
import WaitingParticipant from "../WaitingParticipant/WaitingParticipant";
import TertiaryButton from "../TertiaryButton/TertiaryButton";
import admitAllIcon from "../../Resources/InCallImages/acceptall.svg";
// import { CONFIGURATIONS } from "../../Constants/AppConstants";
import MuteAllIcon from "../../Resources/InCallImages/MuteAllIcon.svg";
import proConfService from "../../Services/ProConfService";

export type ParticipantsPanelType = {
  className?: string;
  toggleParticipantsPanel?: () => void;
  setInvitePopup: (value: boolean) => void;
  allowParticipant?: (value: string | Array<string>) => void;
  denyParticipant?: (value: string) => void;
};

const ParticipantsPanel: FunctionComponent<ParticipantsPanelType> = ({
  className = "",
  toggleParticipantsPanel,
  setInvitePopup,
  allowParticipant = () => {},
  denyParticipant = () => {},
}) => {
  const participants = useSelector(
    (state: any) => state.ParticipantReducer.participants
  );
  const localParticipant = useSelector(
    (state: any) => state.ParticipantReducer.localParticipant
  );
  const waitingLobby = useSelector(
    (state: any) => state.ParticipantReducer.waitingLobby
  );

  const { roomName, roomId } = useSelector((state: any) => state.RoomReducer);

  const { configurations } = useSelector((state: any) => state.LoginReducer);

  // Invite link
  const link =
    process.env.REACT_APP_BASE_URL + "/" + roomId + "?room_name=" + roomName;
  const [copied, setCopied] = useState(false);

  console.log("Participants in participants panel: ", participants);

  // Copy meeting link to clipboard
  const handleCopy = (e: any) => {
    //@ts-ignore
    // navigator.permissions.query({ name: "clipboard-write" }).then((result) => {
    //   if (result.state === "granted" || result.state === "prompt") {
    /* write to the clipboard now */
    navigator.clipboard
      .writeText(link)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset the copied state after 2 seconds
      })
      .catch((err) => console.error("Failed to copy: ", err));
    // } else {
    //   console.error('Clipboard write permission denied.');
    // }
    //});
  };

  const handleAdmitAllParticipants = (waitingLobby: any) => {
    console.log("waitingLobby: ", waitingLobby);
    const waitingParticipantsId = waitingLobby?.map(
      (WaitingParticipant: any) => {
        return WaitingParticipant.memberId;
      }
    ) as string[];

    allowParticipant(waitingParticipantsId);
  };

  const handleMuteAllRemoteParticipants = () => {
    console.log(
      "proConfService?.room?.participants.values(): ",
      Array.from(proConfService?.room?.participants.values())
    );
    const participant = Array.from(
      proConfService?.room?.participants.values()
    )?.map((participant: any) => {
      return participant.getId();
    }) as string[];
    if (participant?.length) {
      proConfService?.muteParticipant(participant, "audio");
    }
  };

  return (
    <div className={`participantspanel ${className}`} id="participantspanel">
      <PanelTitleBar
        titleText={getTranslation("participants")}
        toggleButton={false}
        dropdown={false}
        onClickCloseIcon={toggleParticipantsPanel}
      />
      <div className="participantscontainer">
        {(localParticipant?.isModerator || localParticipant?.isOwner) && (
          <div className="participantsbuttoncontainer">
            <PrimaryButtonSmall onClick={() => setInvitePopup(true)} />
            <SecondaryButtonSmall
              buttonLabel={
                copied
                  ? getTranslation("copiedLink")
                  : getTranslation("copyLink")
              }
              onClick={(e: any) => handleCopy(e)}
              copyIcon={true}
            />
          </div>
        )}
        <div className="participantspanelbody">
          {/* waiting list */}
          {CONFIGURATIONS.WAITING_ROOM in configurations &&
            waitingLobby.length !== 0 && (
              <div className="waitinglistcontainer">
                <div className="waitingtitlebar">
                  <div className="waiting-title-container">
                    <div className="waitingtitle">Waiting</div>
                    <div className="waitingtitle">
                      {" "}
                      {"(" + (waitingLobby?.length || 0) + ")"}
                    </div>
                  </div>

                  <TertiaryButton
                    name={getTranslation("admitAll")}
                    icon={admitAllIcon}
                    click={() => handleAdmitAllParticipants(waitingLobby)}
                  />
                </div>
                <div className="waitingparticipants">
                  {waitingLobby.map((waitingParticipant: any) => {
                    return (
                      <WaitingParticipant
                        key={waitingParticipant.memberId}
                        waitingParticipant={waitingParticipant}
                        allowParticipant={allowParticipant}
                        denyParticipant={denyParticipant}
                      />
                    );
                  })}
                </div>
              </div>
            )}
          {/* )} */}
          <div className="incalllistcontainer">
            <div className="incalltitlebar">
              <div className="incalltitleandcount">
                <div className="waitingtitle">{getTranslation("inCall")}</div>
                <div className="waitingtitle">
                  {"(" + (participants?.length || 0) + ")"}
                </div>
              </div>

              {CONFIGURATIONS.MODERATOR_CONTROL in configurations &&
                localParticipant?.isModerator && (
                  <TertiaryButton
                    name={getTranslation("muteAll")}
                    icon={MuteAllIcon}
                    click={handleMuteAllRemoteParticipants}
                  />
                )}
            </div>
            <div className="incallparticpantlist">
              {participants.length > 0 &&
                participants?.map((participant: any) => (
                  <InCallParticipant
                    key={participant?.sid}
                    showMicStatus={
                      participant?.audioTracks &&
                      participant?.audioTracks[0]?.isEnabled
                        ? true
                        : false
                    }
                    showVideoStatus={
                      participant?.videoTracks &&
                      participant?.videoTracks[0]?.isEnabled
                        ? true
                        : false
                    }
                    participantName={participant?.identity}
                    participantRole={
                      participant?.isModerator
                        ? getTranslation("moderator")
                        : ""
                    }
                    participant={participant}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParticipantsPanel;
