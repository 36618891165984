import { FunctionComponent } from "react";
import "./TertiaryButton.scss";

export type TertiaryButtonType = {
  className?: string;
  name?: string;
  icon?: string;
  click: () => void;
};

const TertiaryButton: FunctionComponent<TertiaryButtonType> = ({
  className = "",
  name,
  icon,
  click,
}) => {
  return (
    <button className={`tertiarybutton ${className}`} onClick={click}>
      {icon && (
        <img className="tertiarybuttonicon" alt="MuteAllIcon" src={icon} />
      )}
      <div className="mute-all">{name}</div>
    </button>
  );
};

export default TertiaryButton;
