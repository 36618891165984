import { FunctionComponent } from "react";
import "./WaitingParticipant.scss";
import MeetingParticipantInfo from "../MeetingParticipantInfo/MeetingParticipantInfo";
import DenyIcon from "../../Resources/InCallImages/DenyIcon.svg";
import allowiconimage from "../../Resources/InCallImages/allowiconimage.svg";

export type WaitingParticipantType = {
  className?: string;
  propMinWidth?: string;
  propMinHeight?: string;
  propBorder?: string;
  initialsText?: string;
  propFontSize?: string;
  waitingParticipant?: { memberId: string; memberName: string };
  allowParticipant?: (value: string) => void;
  denyParticipant?: (value: string) => void;
};

const WaitingParticipant: FunctionComponent<WaitingParticipantType> = ({
  className = "",
  propMinWidth,
  propMinHeight,
  propBorder,
  initialsText,
  propFontSize,
  waitingParticipant = { memberId: "", memberName: "" },
  allowParticipant = () => {},
  denyParticipant = () => {},
}) => {
  return (
    <div className={`waitingparticipant1 ${className}`}>
      <MeetingParticipantInfo participantName={waitingParticipant.memberName} />
      <div className="allowdenycontrols1">
        <button
          className="denybutton1"
          onClick={() => denyParticipant(waitingParticipant.memberId)}
        >
          <img className="denyicon1" alt="" src={DenyIcon} />
        </button>
        <button
          className="allowbutton1"
          onClick={() => allowParticipant(waitingParticipant.memberId)}
        >
          <div className="allowicon1">
            <img className="allowiconimage" alt="" src={allowiconimage} />
          </div>
        </button>
      </div>
    </div>
  );
};

export default WaitingParticipant;
