import { FunctionComponent } from "react";
import "./ButtonLeave.scss";
import { onEndMeeting, onleaveCall } from "../../Redux/Actions/ToolbarActions";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { store } from "../../Redux/store";
import { ROUTES } from "../../Routes/RouteConstants";
import EndCallMediaControlIcon from "../../Resources/InCallImages/EndCallMediaControlIcon.svg";
import LeaveMeetingMediaControlIcon from "../../Resources/InCallImages/LeaveMeetingMediaControlIcon.svg";
import { getTranslation } from "../../Resources/Localization/i18n";

export type ButtonLeaveType = {
  className?: string;
  onClose: () => void;
};

const ButtonLeave: FunctionComponent<ButtonLeaveType> = ({
  className = "",
  onClose,
}) => {
  const navigate = useNavigate();
  const isModerator = useSelector(
    (state: any) => state.ParticipantReducer.localParticipant?.isModerator
  );
  const isGuestFlow = store.getState()?.RoomReducer.userName ?? false;

  const leaveMeeting = () => {
    if (isGuestFlow) {
      onleaveCall();
      navigate(ROUTES.ROOT_PATH);
    } else if (isModerator) {
      onleaveCall();
      navigate(ROUTES.MEETING_LIST);
    } else {
      onEndMeeting();
    }
  };

  const endMeetingForAll = () => {
    onEndMeeting();
    onClose();
  };

  return (
    <div className={`buttonleave ${className}`}>
      {isModerator && (
        <button className="buttonendcall" onClick={endMeetingForAll}>
          <img
            className="callicon"
            alt="EndCallMediaControlIcon"
            src={EndCallMediaControlIcon}
          />
          <div className="end-for-all">{getTranslation("endForAll")}</div>
        </button>
      )}
      <button className="hidedefault" onClick={leaveMeeting}>
        <img
          className="callicon"
          alt="LeaveMeetingMediaControlIcon"
          src={LeaveMeetingMediaControlIcon}
        />
        <div className="leave-meeting">{getTranslation("leaveMeeting")}</div>
      </button>
    </div>
  );
};

export default ButtonLeave;
