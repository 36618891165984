import { FunctionComponent } from "react";
import "./ConfirmationPopup.scss";
import { getTranslation } from "../../Resources/Localization/i18n";

export type ConfirmationPopupType = {
  className?: string;
  onClose?: () => void;
  confirmationTitle?: string;
  confirmationMessage?: string;
  actionName?: string;
  cancelActionName?: string;
  onCancelClick?: () => void;
  onActionClick?: () => void;
};

const ConfirmationPopup: FunctionComponent<ConfirmationPopupType> = ({
  className = "",
  onClose,
  confirmationTitle,
  confirmationMessage,
  actionName,
  cancelActionName,
  onCancelClick,
  onActionClick,
}) => {
  return (
    <div className={`confirmationpopup ${className}`}>
      <div className="confirmationpanel">
        <div className="confirmationpopuptitlebar">
          <b className="confirmationpopuptitle">{confirmationTitle}</b>
        </div>
        <div className="confirmation">{confirmationMessage}</div>
        <div className="confirmationbuttoncontainer">
          <button className="buttonsecondary" onClick={onCancelClick}>
            <b className="buttonlabel">{cancelActionName || getTranslation("cancel")}</b>
          </button>
          <button className="buttonprimary" onClick={onActionClick}>
            <b className="buttonprimarylabel">{actionName}</b>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPopup;
