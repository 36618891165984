import React, { useImperativeHandle, forwardRef } from "react";
import { notification } from "antd";
import "./Notification.scss";
import { store } from "../../Redux/store";
import ProfilePic from "../../Resources/Images/profileIconSmall.png";
import { getTranslation } from "../../Resources/Localization/i18n";
import SecondaryButtonSmall from "../SecondaryButtonSmall/SecondaryButtonSmall";

interface NotificationRef {
  openNotification: (
    type: "success" | "error" | "warning" | "info",
    message: string,
    description?: string,
    buttonClick?: () => {}
  ) => void;
}
/**
 *
 * @param ref ref to the component
 * @returns returns jsx to display the notifications/toast
 */
const NotificationComponent: React.ForwardRefRenderFunction<
  NotificationRef,
  {}
> = (_, ref) => {
  // Function to play the notification sound
  const playNotificationSound = () => {
    const audio = new Audio("/ding.mp3"); // Replace with the path to your sound file
    audio.play();
  };

  // On click of view button of info notification
  // Participant panel is getting opened
  // Once the panel is open notification should be detroyed
  if (store.getState()?.ToolbarReducer?.participantPanelOpen) {
    notification.destroy();
  }

  const openNotification = (
    type: "success" | "error" | "warning" | "info",
    message: string,
    description?: string,
    buttonClick?: () => void
  ) => {
    // For waiting lobby notifications,
    // need to add sound and show combine notifications for all waiting lobby users
    // hence destroying the previous notifications
    if (type === "info") {
      notification.destroy();
      playNotificationSound();
    }

    notification[type]({
      message: message,
      description: description,
      className: "custom-notification", // Apply custom class
      placement: "topRight",
      duration:
        type === "info" && !store.getState().ToolbarReducer.participantPanelOpen
          ? 6
          : 2,
      onClick: () => {
        // console.log('Notification Clicked!');
      },
      icon: type === "info" && (
        <img alt="Profile pic" src={ProfilePic} width={30} />
      ),
      btn:
        type === "info" &&
        !store.getState().ToolbarReducer.participantPanelOpen ? (
          <SecondaryButtonSmall
            buttonLabel={getTranslation("view")}
            onClick={buttonClick}
          />
        ) : null,
    });
  };

  // Expose the openNotification function to the parent component
  useImperativeHandle(ref, () => ({
    openNotification,
  }));

  return <div></div>;
};

export default forwardRef(NotificationComponent);
