import { FunctionComponent, useEffect, useState, useRef, useMemo } from "react";
import "./CallScreen.scss";

import { useMobileOrientation } from "react-device-detect";
import CallHeader from "../../Common Components/CallHeader/CallHeader";
import ActionToolBar from "../../Common Components/ActionToolBar/ActionToolBar";
import TranscriptPanel from "../../Common Components/TransciptPanel/TranscriptPanel";
import ParticipantsPanel from "../../Common Components/ParticipantsPanel/ParticipantsPanel";
import ParticipantTile from "../../Common Components/ParticipantTile/ParticipantTile";
import {
  getThumbnailTileSize,
  getCallTileSize,
  isDesktopDevice,
  isTabletOrIpad,
  isMobileDevice,
  getAddressBarHeight,
  getElementSizeExcludingPadding,
  isBiggerTabOrIpad,
} from "../../Utility/Utils";
import { useSelector } from "react-redux";
import PortalPopup from "../../Common Components/PortalPopup/PortalPopup";
import InviteViaEmailPopUp from "../InviteParticipant/InviteViaEmailPopup";
import { Track } from "proconf-web-sdk";
import { onRecordClick } from "../../Redux/Actions/ToolbarActions";
import JoinDialogueBox from "../../Common Components/JoinDialogueBox/JoinDialogueBox";
import proConfService from "../../Services/ProConfService";
import { store } from "../../Redux/store";
import { ON_PARTICIPANT_PANEL_TOGGLE } from "../../Redux/ReduxConstants";

const CallScreen: FunctionComponent = () => {
  const [showChatPanel, setShowChatPanel] = useState(false);
  const [showTranscriptPanel, setShowTranscriptPanel] = useState(false);
  const [activePanel, setActivePanel] = useState<Array<string>>([]);

  const [openInvitePopup, setOpenInvitePopup] = useState(false);

  const [pinnedParticipant, setPinnedParticipant] = useState<any>(null);
  const [participantInFocus, setParticipantInFocus] = useState<any>(null);

  const [callTileCount, setCallTileCount] = useState(0);
  const [callScreenHeight, setCallScreenHeight] = useState("100vh");
  const [bigTileSize, setBigTileSize] = useState({
    tileHeight: 0,
    tileWidth: 0,
  });
  const [smallTileSize, setSmallTileSize] = useState({
    tileHeight: 0,
    tileWidth: 0,
    noOfUsersToFit: 0,
  });

  const sidePanelRef = useRef<HTMLDivElement>(null);

  const { isLandscape, isPortrait } = useMobileOrientation();

  const participants = useSelector(
    (state: any) => state.ParticipantReducer?.participants
  );
  const isRemoteScreenShared = useSelector(
    (state: any) => state.ProConfReducer?.isRemoteSSOn
  );
  const screensharingParticicpants = useSelector((state: any) => {
    return state.ParticipantReducer?.screenSharingParticipants;
  });

  const activeRecording = useSelector(
    (state: any) => state.ToolbarReducer.recordingStarted
  );
  const participantPanelOpen = useSelector(
    (state: any) => state.ToolbarReducer.participantPanelOpen
  );

  const {
    audioMute,
    videoMute,
    screenShareStarted,
    isNoiseCancellationEnabled,
  } = useSelector((state: any) => state.ToolbarReducer);
  // alert("isBiggerTabOrIpad: " + isBiggerTabOrIpad);

  const toggleChatPanel = () => {
    if (!showChatPanel) {
      setActivePanel([...activePanel, "CHAT"]);
      if (!isDesktopDevice) {
        setShowTranscriptPanel(false);
        store.dispatch({ type: ON_PARTICIPANT_PANEL_TOGGLE });
        setActivePanel(["CHAT"]);
      }
    } else {
      let currentActivePanel = [...activePanel];
      currentActivePanel.splice(currentActivePanel.indexOf("CHAT"), 1);
      setActivePanel(currentActivePanel);
    }
    setShowChatPanel(!showChatPanel);
  };

  const toggleTranscriptPanel = () => {
    if (!showTranscriptPanel) {
      setActivePanel([...activePanel, "TRANSCRIPT"]);
      if (!isDesktopDevice) {
        setShowChatPanel(false);
        store.dispatch({ type: ON_PARTICIPANT_PANEL_TOGGLE });
        setActivePanel(["TRANSCRIPT"]);
      }
    } else {
      let currentActivePanel = [...activePanel];
      currentActivePanel.splice(currentActivePanel.indexOf("TRANSCRIPT"), 1);
      setActivePanel(currentActivePanel);
    }
    setShowTranscriptPanel(!showTranscriptPanel);
  };

  const toggleParticipantsPanel = () => {
    if (!participantPanelOpen) {
      setActivePanel([...activePanel, "PARTICIPANTS"]);
      if (!isDesktopDevice) {
        setShowTranscriptPanel(false);
        store.dispatch({ type: ON_PARTICIPANT_PANEL_TOGGLE });
        setActivePanel(["PARTICIPANTS"]);
      }
    } else {
      let currentActivePanel = [...activePanel];
      currentActivePanel.splice(currentActivePanel.indexOf("PARTICIPANTS"), 1);
      setActivePanel(currentActivePanel);
    }
    store.dispatch({ type: ON_PARTICIPANT_PANEL_TOGGLE });
  };

  const calculateCallScreenHeight = () => {
    if (isMobileDevice || isTabletOrIpad) {
      const addressBarHeight = getAddressBarHeight();
      setCallScreenHeight(`calc(100vh - ${addressBarHeight}px)`);
    }
  };

  const adjustCallScreenAndTileSize = () => {
    setTileLayout();
    calculateCallScreenHeight();
  };

  useEffect(() => {
    // alert("calling setTileLayout in componentDidMount")
    adjustCallScreenAndTileSize();
    if (!isDesktopDevice) {
      window.addEventListener("resize", adjustCallScreenAndTileSize);
      // confirm with Ishwari
      window.addEventListener("orientationchange", adjustCallScreenAndTileSize);
    }
    return () => {
      window.removeEventListener("resize", adjustCallScreenAndTileSize);
      window.removeEventListener(
        "orientationchange",
        adjustCallScreenAndTileSize
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTileLayout();
    // Ishwari: add a comment here why this is needed again if we have already added in compDidMount useEffect
    if (!isDesktopDevice) {
      window.addEventListener("resize", adjustCallScreenAndTileSize);
      window.addEventListener("orientationchange", adjustCallScreenAndTileSize);
    }
  }, [
    participants.length,
    callTileCount,
    isRemoteScreenShared,
    isLandscape,
    activePanel,
  ]);

  const reAttachVideos = (allParticipants: any) => {
    console.log("****************** reAttachVideos:", allParticipants);
    allParticipants?.forEach((singleParticipant: any) => {
      let tracks = Array.from(
        singleParticipant.videoTracks.values()
      ) as Track[];

      let mediaElementId = "video-";
      let trackIndex = 0;
      if (singleParticipant.isScreenShareParticipant) {
        console.log("isScreenShareParticipant: ", true);
        mediaElementId = "screen-";
        trackIndex = 1;
      }
      mediaElementId += singleParticipant?.sid;
      const videoElement = document.getElementById(
        mediaElementId
      ) as HTMLVideoElement;
      // reattach the video to only those video elements that have participant id changed
      console.log("tracks[trackIndex]: ", tracks[trackIndex]?.isEnabled);
      console.log(
        "videoElement?.id: ",
        videoElement?.id,
        "mediaElementId: ",
        mediaElementId
      );
      if (
        videoElement?.id !== mediaElementId ||
        (videoElement?.id === mediaElementId && videoElement?.paused)
      ) {
        console.log("reattaching track for user ", mediaElementId);
        try {
          tracks[trackIndex]?.attach(videoElement);
        } catch (error) {
          console.warn("Error in re-attaching track: ", error);
        }
      }
    });
  };

  useMemo(() => {
    // this hook is required to attach the video streams
    setTimeout(() => {
      let screenshareParticipants = [];
      if (isRemoteScreenShared) {
        screenshareParticipants.push(
          screensharingParticicpants[screensharingParticicpants.length - 1]
        );
      }
      reAttachVideos([...screenshareParticipants, ...participants]);
    }, 1000);
  }, [isRemoteScreenShared, isLandscape, activePanel]);

  const setTileLayout = () => {
    console.log(
      ".................................................. in setTileLayout"
    );
    let parentElement = document.getElementById("calltiles");
    if (parentElement) {
      const CallTileContainerSize = getElementSizeExcludingPadding(
        parentElement,
        false
      );
      const callTileSize = getCallTileSize(
        CallTileContainerSize,
        callTileCount,
        participants,
        CallTileContainerSize.vGap,
        CallTileContainerSize.hGap
      );
      setBigTileSize(callTileSize);

      // calculate tile size for thumbnail panel
      if (
        screensharingParticicpants.length +
          participants.length -
          callTileCount >
        0
      ) {
        const sidePanelContainer = sidePanelRef.current;
        if (sidePanelContainer) {
          const sidePanelContainerSize = getElementSizeExcludingPadding(
            sidePanelContainer,
            true
          );
          const thumbnailSize = getThumbnailTileSize(
            sidePanelContainerSize,
            sidePanelContainerSize.vGap,
            sidePanelContainerSize.hGap
          );
          setSmallTileSize(thumbnailSize);
        }
      }
    }
  };

  useEffect(() => {
    // tile layout
    // Desktop: 4
    // Screen sharing : 1
    // Mobile: portrait: when no panel is open: 3
    //                 : when any panel is open: 1
    //        landscape: when no panel is open: 3
    //                 : when any panel is open: 4
    // Tab / ipad: portrait: when no panel is open: 4
    //                 : when any panel is open: 1
    //        landscape: when no panel is open: 4
    //                 : when any panel is open: 4
    let callTileCount = Math.min(participants.length, 4);

    if (isRemoteScreenShared) {
      callTileCount = 1;
    } else if (
      (isMobileDevice || isTabletOrIpad) &&
      !isBiggerTabOrIpad &&
      isPortrait &&
      activePanel.length
    ) {
      callTileCount = 1;
    } else if (isMobileDevice && !activePanel.length) {
      callTileCount = 3;
    }
    setCallTileCount(callTileCount);
  }, [activePanel, isLandscape, isRemoteScreenShared, participants.length]);

  useEffect(() => {
    if (!pinnedParticipant) {
      if (isRemoteScreenShared) {
        setParticipantInFocus(screensharingParticicpants[0]);
      }
      //  else if (participants.length && callTileCount === 1) {
      //   setParticipantInFocus(participants[0]);
      // }
      else {
        setParticipantInFocus(null);
      }
    }
  }, [callTileCount, isRemoteScreenShared]);
  let allParticipants = []; // [...screensharingParticicpants, ...participants];
  if (screensharingParticicpants.length && isRemoteScreenShared) {
    // TODO: fix to remove screensharingparticipants when screen share stopeed
    // remove this temporary fix once TODO itme taken care of
    allParticipants.push(
      screensharingParticicpants[screensharingParticicpants.length - 1]
    );
  }
  if (participants.length) {
    allParticipants = allParticipants.concat(participants);
  }

  let thumbnailParticipants = allParticipants
    .slice(participantInFocus ? 1 : callTileCount)
    .slice(0, smallTileSize.noOfUsersToFit);

  const allowParticipant = (memberId: string | Array<string>) => {
    proConfService.admitParticipant(memberId);
    store.dispatch({
      type: "REMOVE_WAITING_LOBBY_ALERT",
    });
  };
  const denyParticipant = (memberId: string) => {
    proConfService.rejectParticipant(memberId);
    store.dispatch({
      type: "REMOVE_WAITING_LOBBY_ALERT",
    });
  };

  return (
    <div
      className={
        isMobileDevice && isLandscape && activePanel.length
          ? "callscreen scrollableView"
          : "callscreen"
      }
      style={{ height: callScreenHeight }}
    >
      <CallHeader showRecordingIcon={activeRecording} />
      <div className="callbody" id="callbody">
        {(showChatPanel || showTranscriptPanel) && (
          <div className="leftnavcontainer" id="leftnavcontainer">
            {showTranscriptPanel && (
              <TranscriptPanel toggleTranscriptPanel={toggleTranscriptPanel} />
            )}
            {/* Hide chat panel */}
            {/* {showChatPanel && <ChatPanel toggleChatPanel={toggleChatPanel} />} */}
          </div>
        )}
        <div className="callbodycontainer">
          <div className="callmaincontainer">
            <div className="calltiles" id="calltiles">
              {participantInFocus ? (
                <ParticipantTile
                  // key={participantInFocus?.sid}
                  participant={participantInFocus}
                  screenShared={participantInFocus?.videoTracks[1]?.isEnabled}
                  // notToUseRatio={true}
                />
              ) : (
                participants
                  .slice(0, Math.min(participants.length, callTileCount))
                  .map((participant: any) => (
                    <ParticipantTile
                      key={participant?.sid}
                      tileSize={bigTileSize}
                      participant={participant}
                      notToUseRatio={participants.length === 1}
                    />
                  ))
              )}
            </div>
          </div>
          {participants.slice(callTileCount).length > 0 && (
            <div
              className="sidetilespanel"
              id="sidetilespanel"
              ref={sidePanelRef}
            >
              {thumbnailParticipants.map((participant: any) => (
                <ParticipantTile
                  key={
                    (participant.isScreenShareParticipant
                      ? "screen-"
                      : "video-") + participant?.sid
                  }
                  tileSize={smallTileSize}
                  participant={participant}
                  smallTile={true}
                  screenShared={participant.isScreenShareParticipant}
                />
              ))}
            </div>
          )}
        </div>
        {participantPanelOpen && (
          <ParticipantsPanel
            toggleParticipantsPanel={toggleParticipantsPanel}
            setInvitePopup={setOpenInvitePopup}
            allowParticipant={allowParticipant}
            denyParticipant={denyParticipant}
          />
        )}

        {openInvitePopup && (
          <PortalPopup placement="Centered">
            <InviteViaEmailPopUp setInvitePopup={setOpenInvitePopup} />
          </PortalPopup>
        )}
      </div>
      <ActionToolBar
        activeRecording={activeRecording}
        showChatPanel={showChatPanel}
        participantPanelOpen={participantPanelOpen}
        showTranscriptPanel={showTranscriptPanel}
        toggleChatPanel={toggleChatPanel}
        toggleTranscriptPanel={toggleTranscriptPanel}
        toggleParticipantsPanel={toggleParticipantsPanel}
        toggleRecording={() => onRecordClick(!activeRecording)}
        isRemoteScreenShared={isRemoteScreenShared}
        audioMute={audioMute}
        videoMute={videoMute}
        screenShareStarted={screenShareStarted}
        participants={participants}
        isNoiseCancellationEnabled={isNoiseCancellationEnabled}
      />
    </div>
  );
};

export default CallScreen;
