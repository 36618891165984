import { FunctionComponent, useState } from "react";
import { DotLottiePlayer } from "@dotlottie/react-player";
import { getTranslation } from "../../Resources/Localization/i18n";
import PortalPopup from "../../Common Components/PortalPopup/PortalPopup";
import ConfirmationPopup from "../../Common Components/ConfirmationPopup/ConfirmationPopup";
import "./WaitingLobby.scss";

export type WaitingScreenType = {
  className?: string;
  onLeave?: () => void;
};

const WaitingScreen: FunctionComponent<WaitingScreenType> = ({
  className = "",
  onLeave = () => {},
}) => {
  const [isLeaveClicked, setIsLeaveClicked] = useState(false);
  const handleLeave = () => {
    // Moderator will get LOBBY_USER_LEFT event on refreshing this waiting page
    onLeave();
  };

  return (
    <>
      {isLeaveClicked && (
        <PortalPopup overlayColor="rgba(0, 0, 0, 0.2)" placement="Centered">
          <ConfirmationPopup
            confirmationTitle={getTranslation("leaveTitle")}
            confirmationMessage={getTranslation("leaveMessage")}
            actionName={getTranslation("cancel")}
            cancelActionName={getTranslation("leave")}
            onCancelClick={() => handleLeave()}
            onActionClick={() => setIsLeaveClicked(false)}
          />
        </PortalPopup>
      )}

      <div className={`waitinglobbysub ${className}`}>
        <div className="waiting-message-container">
          <DotLottiePlayer
            className="waitinglobbyspinner"
            autoplay
            src="/qlkf1josod.json"
            loop
          />
          <div className="waiting-message">
            <p className="just-a-moment">{getTranslation("moment")}</p>
            <p className="just-a-moment">{getTranslation("waitingText")}</p>
          </div>
          <button
            className="tertiary-button1"
            onClick={() => setIsLeaveClicked(true)}
          >
            <img
              className="secondarybuttonsmallicon1"
              alt=""
              src="/secondarybuttonsmallicon.svg"
            />
            <b className="secondarybuttonsmalllabel1">Leave</b>
          </button>
        </div>
        <div className="fun-fact">
          <div className="fun-fact-title">Fun Fact</div>
          <i className="fun-fact-text">
            “Penguins huddle together in large groups to share warmth during
            harsh winters, a perfect example of how collaboration can help us
            overcome challenges.”
          </i>
        </div>
      </div>
    </>
  );
};
export default WaitingScreen;
