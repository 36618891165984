import {
  ProConfManager,
  Room,
  PROCONF_EVENTS,
  Participant,
  Track,
  ProConfErrorCode,
  ProConfErrorMessages,
} from "proconf-web-sdk";
import { store } from "../Redux/store";
import {
  delay,
  getFilteredConfiguration,
  waitForVideoElement,
} from "../Utility/Utils";
import {
  ON_SCREEN_SHARE_TOGGLE,
  SET_AVAILABLE_CONFIGURATIONS,
  CLEAR_INCALL_REDUCER,
  CALL_IN_PROGRESS,
  CLEAR_PARTICIPANTS,
  CLEAR_ROOM,
  RESET_TOOLBAR_STATE,
  SET_REMOTE_SS,
  SET_ROOM_ENDED,
  CLEAR_NOTIFICATIONS,
  RESET_RECORDING_STATE,
  SET_AUDIO_INPUT_DEVICES,
  SET_AUDIO_OUTPUT_DEVICES,
  SET_VIDEO_DEVICES,
  SET_VIDEO_PERMISSION,
  SET_AUDIO_PERMISSION,
  SET_SELECTED_MIC,
  SET_SELECTED_CAMERA,
  SET_SELECTED_SPEAKER,
  ON_PARTICIPANT_PANEL_TOGGLE,
  SET_LOBBY_SCREEN,
  ON_MIC_MUTE_UNMUTE,
  ON_VIDEO_MUTE_UNMUTE,
} from "../Redux/ReduxConstants";
import { applyAudioOutputDeviceSelection } from "../Utility/DeviceUtils";
import { setTranscriptions } from "../Redux/Actions/InCallAction";
import {
  setErrorNotification,
  setInfoNotification,
  setSuccessNotification,
  setWarningNotification,
} from "../Redux/Actions/NotificationAction";
import { getTranslation } from "../Resources/Localization/i18n";
import { onScreenSharing } from "../Redux/Actions/ToolbarActions";
import { CONFIGURATIONS } from "../Constants/AppConstants";

class ProConfService {
  static proConfManager: ProConfManager | null = null;
  static deviceManager: any | null = null;

  public room: Room | null | undefined = null;
  public localParticipant: Participant | undefined | null = undefined;
  public tracks: any;
  private isProconfInitialized = false;
  public screenTrack: Track | undefined | null = undefined;
  //public screenTracks: Track[] | undefined | null = undefined;
  public isCallInProgress = false;

  public localVideoEl: HTMLVideoElement | null =
    (document.getElementById("local-video") as HTMLVideoElement) || null;

  private screenShareDialogOpen = false;

  constructor() {
    this.localVideoEl = document.getElementById(
      "local-video"
    ) as HTMLVideoElement;
  }

  // Initialize ProConfManager
  public async initProConf() {
    try {
      ProConfService.proConfManager = ProConfManager.getInstance();
      ProConfService.deviceManager =
        ProConfService.proConfManager?.getMediaDeviceManager();
      store.dispatch({ type: "IS_PROCONF_INIT", payload: false });
      let token = store.getState()?.LoginReducer?.token;
      if (token)
        await ProConfService.proConfManager
          .init({
            appServerUrl: process.env.REACT_APP_SERVER_DOMAIN,
            appBearerToken: token,
            // noiseCancellationWasmUrl: 'http://127.0.0.1:5500/rnnoise.wasm' // Passing locally hosted rnn wasm
          })
          .then(() => {
            store.dispatch({
              type: "INIT_PROCONF_SUCCESS",
              payload: true, //ProConfService.proConfManager,
            });
            store.dispatch({ type: "IS_PROCONF_INIT", payload: true });

            console.log("Initialization successful!");
            this.isProconfInitialized = true;
          });
      await this.getCallConfig();
    } catch (error) {
      console.error("Initialization failed:", error);
      store.dispatch({ type: "PROCONF_ERROR", payload: error });
      throw error;
    }
  }

  //get sdk version
  public async getSDKVersion() {
    const version = ProConfService?.proConfManager?.getVersion();
    console.log("version: ", version);
    return version;
  }

  // Get call configurations
  public async getCallConfig() {
    const Configs = await ProConfService?.proConfManager?.getCallConfig();
    if (Configs) {
      const filteredConfigs = getFilteredConfiguration(Configs);
      console.log("getCallConfig configurations: ", Configs, filteredConfigs);
      store.dispatch({
        type: SET_AVAILABLE_CONFIGURATIONS,
        payload: filteredConfigs,
      });
      console.log("getCallConfig Configs: ", Configs);
    }
    return Configs;
  }

  // Enable/Disable Transcription
  public enableTranscription(isEnabled: boolean) {
    if (this.room) {
      console.log("this.room: ", this.room);
      this.room?.enableTranscription(isEnabled);
    }
  }

  // Create local tracks from selected media devices
  public async createTracks(options: any, audio: boolean, video: boolean) {
    let params = {};
    if (ProConfService.proConfManager && this.isProconfInitialized) {
      if (audio && video) {
        if (this.tracks?.length > 0) {
          this.tracks?.forEach((track: any) => {
            track?.stop();
          });
          this.tracks = [];
        }
        params = {
          devices:
            CONFIGURATIONS.VIDEO_CALL in
            store.getState()?.LoginReducer?.configurations
              ? ["audio", "video"]
              : ["audio"],
          cameraDeviceId: options?.cameraDeviceId,
          micDeviceId: options?.micDeviceId,
        };
      } else if (audio && !video) {
        if (this.tracks?.length > 0) {
          this.tracks?.forEach((track: any) => {
            if (track.kind === "audio") {
              track?.stop();
              this.tracks = this.tracks?.filter((t: any) => t.kind !== "audio");
            }
          });
        }
        params = {
          devices: ["audio"],
          micDeviceId: options?.micDeviceId,
        };
      } else if (!audio && video) {
        if (this.tracks?.length > 0) {
          this.tracks?.forEach((track: any) => {
            if (track.kind === "video") {
              track?.stop();
              this.tracks = this.tracks?.filter((t: any) => t.kind !== "video");
            }
          });
        }
        params = {
          devices: ["video"],
          cameraDeviceId: options?.cameraDeviceId,
        };
      }

      try {
        const tracks = await ProConfService.proConfManager?.createLocalTracks(
          params
        );
        if (this.tracks) {
          this.tracks = [...this.tracks, ...tracks];
        } else {
          this.tracks = [...tracks];
        }
      } catch (error) {
        console.log("createLocalTracks error: ", error);
      }
      return this.tracks;
    }
  }

  // Start meeting
  public async startMeeting(
    roomName: string,
    username: string,
    roomId?: string
  ) {
    const audioMute = store.getState()?.ToolbarReducer?.audioMute;
    const videoMute = store.getState()?.ToolbarReducer?.videoMute;
    const audioPermission = store.getState()?.InCallReducer?.audioPermission;
    const videoPermission = store.getState()?.InCallReducer?.videoPermission;
    const configurations = store.getState()?.LoginReducer?.configurations;
    this.tracks = [];
    store.dispatch({ type: "JOIN_ROOM_SUCCESS", payload: false });

    if (ProConfService.proConfManager && this.isProconfInitialized) {
      try {
        const roomOptions = {
          roomName: roomName,
          roomId: roomId,
          participantName: username.trim(),
        };

        console.log("roomOptions: ", roomOptions);

        this.room = await ProConfService.proConfManager?.startMeeting(
          roomOptions
        );
        if (this.room) {
          store.dispatch({ type: "SET_ROOM_ID", payload: this.room?.id });
          store.dispatch({ type: "JOIN_ROOM_SUCCESS", payload: true });
          store.dispatch({
            type: "SET_TRANSCRIPTION_STATE",
            payload: this.room.isTranscribing,
          });

          this.isCallInProgress = true;
          this.bindRoomEvents(this.room);
          this.localParticipant = this.room.localParticipant as Participant;

          const tracksObj = [],
            audioTracks = [],
            videoTracks = [];
          await this.clearTracks();
          if (audioMute === false && audioPermission) {
            const localAudioTrack = await this.createAndPublishTrack("audio");
            tracksObj.push(localAudioTrack);
            audioTracks.push(localAudioTrack);
          }
          if (
            videoMute === false &&
            CONFIGURATIONS.VIDEO_CALL in configurations &&
            videoPermission
          ) {
            const localVideoTrack = await this.createAndPublishTrack("video");
            tracksObj.push(localVideoTrack);
            videoTracks.push(localVideoTrack);
          }

          this.setLocalParticipantInStore(tracksObj, audioTracks, videoTracks);
          if (videoTracks && videoTracks.length > 0) {
            const el = await waitForVideoElement(
              "video-" + this.room?.localParticipant?.sid
            );
            videoTracks[0]?.attach(el as HTMLVideoElement);
          }

          // Store already existing remote participants
          this.handleRemoteParticipants(this.room as Room);
          console.log(
            "ProCONF service:::startMeeting: Connected successfully! roomId",
            this.room
          );
          return this.room;
        }
      } catch (error) {
        console.error(
          "ProCONF service:::startMeeting: Connection failed:",
          error
        );
        if (error?.toString()?.includes("max_users")) {
          setErrorNotification(getTranslation("maxParticipantLimit"));
          window?.history?.back();
          throw error;
        } else {
          store.dispatch({ type: "PROCONF_ERROR", payload: error });
          //@ts-ignore
          setErrorNotification(error?.message);
          throw error;
        }
      }
    } else {
      throw new Error(
        "ProCONF service:::ProConfManager is not initialized yet."
      );
    }
  }

  // Join meeting
  public async joinMeeting(
    roomname: string,
    roomId: string,
    username: string,
    isRejoin = false
  ) {
    const audioMute = store.getState()?.ToolbarReducer?.audioMute;
    const videoMute = store.getState()?.ToolbarReducer?.videoMute;
    const audioPermission = store.getState()?.InCallReducer?.audioPermission;
    const videoPermission = store.getState()?.InCallReducer?.videoPermission;
    const configurations = store.getState()?.LoginReducer?.configurations;
    this.tracks = [];
    store.dispatch({ type: "JOIN_ROOM_SUCCESS", payload: false });

    if (ProConfService.proConfManager && this.isProconfInitialized) {
      try {
        const roomOptions = {
          roomName: roomname?.trim(),
          roomId: roomId?.trim(),
          participantName: username?.trim(),
        };
        console.log("roomOptions: roomId", roomOptions);

        this.room = await ProConfService.proConfManager?.joinMeeting(
          roomOptions,
          undefined,
          isRejoin
        );
        if (this.room) {
          store.dispatch({ type: "JOIN_ROOM_SUCCESS", payload: true });

          console.log(
            "ProCONF service:::joinMeeting: Connected successfully!",
            this.room
          );
          this.isCallInProgress = true;

          this.localParticipant = this.room.localParticipant as Participant;
          this.setLocalParticipantInStore([], [], []);
          this.bindRoomEvents(this.room);

          // Store already existing remote participants
          this.handleRemoteParticipants(this.room as Room);

          const tracksObj = [],
            audioTracks = [],
            videoTracks = [];

          if (audioMute === false && audioPermission) {
            const localAudioTrack = await this.createAndPublishTrack("audio");
            tracksObj.push(localAudioTrack);
            audioTracks.push(localAudioTrack);
          }
          if (
            videoMute === false &&
            CONFIGURATIONS.VIDEO_CALL in configurations &&
            videoPermission
          ) {
            const localVideoTrack = await this.createAndPublishTrack("video");
            tracksObj.push(localVideoTrack);
            videoTracks.push(localVideoTrack);
          }

          this.setLocalParticipantInStore(
            tracksObj,
            audioTracks,
            videoTracks,
            true
          );

          if (videoTracks && videoTracks?.length > 0) {
            const el = await waitForVideoElement(
              "video-" + this.room?.localParticipant?.sid
            );
            videoTracks[0]?.attach(el);
          }
        } else {
          console.log("Room not created");
          this.cleanUp();
          this.redirect();
        }
        return this.room;
      } catch (error: any) {
        console.error("joinMeeting: Connection failed:", error);
        if (error?.toString()?.includes("max_users")) {
          setErrorNotification(getTranslation("maxParticipantLimit"));
          window?.history?.back();
          throw error;
        } else if (error.code === ProConfErrorCode.NOT_ALLOWED) {
          setErrorNotification(error?.message);
          this.cleanUp();
          this.redirect();
          throw error;
        } else {
          store.dispatch({ type: "PROCONF_ERROR", payload: error });
          //@ts-ignore
          setErrorNotification(error?.message);
          // Lobby rejected handling
          store.dispatch({ type: SET_LOBBY_SCREEN, payload: false });
          throw error;
        }
      }
    } else {
      throw new Error("ProConfManager is not initialized yet.");
    }
  }

  // Bind room events
  private bindRoomEvents(room: Room) {
    console.log("ProCONF service:::bindRoomEvents:");
    this.handleTrackEvents(room);
    room.on(
      PROCONF_EVENTS.PARTICIPANT_CONNECTED,
      (participant: Participant) => {
        console.log("ProCONF service:::Participant connected:", participant);
        this.bindParticipantEvents(participant);
        store.dispatch({
          type: "ADD_PARTICIPANT",
          payload: {
            ...participant,
            tracks: participant.tracks
              ? Array.from(participant?.tracks?.values())
              : [],
            audioTracks: participant.audioTracks
              ? Array.from(participant?.audioTracks?.values())
              : [],
            videoTracks: participant.videoTracks
              ? Array.from(participant?.videoTracks?.values())
              : [],
            isLocal: false,
          },
        });
        setSuccessNotification(
          // @ts-ignore
          getTranslation("userJoined", {
            user: participant?.identity as string,
          })
        );
      }
    );
    room.on(PROCONF_EVENTS.ROOM_CREATED_TIMESTAMP, (timestamp) => {
      console.log("ROOM_CREATED_TIMESTAMP event: ", timestamp);
      store.dispatch({ type: "SET_MEETING_TIME", payload: timestamp });
    });
    room.on(
      PROCONF_EVENTS.PARTICIPANT_DISCONNECTED,
      (participant: Participant) => {
        console.log("ProCONF service:::Participant disconnected:", participant);
        store.dispatch({
          type: "REMOVE_PARTICIPANT",
          payload: participant?.sid,
        });
        // Remove the participant from screen sharing participant list
        store.dispatch({
          type: "REMOVE_SCREEN_SHARING_PARTICIPANT",
          payload: participant?.sid,
        });
        if (this.room) {
          setWarningNotification(
            // @ts-ignore
            getTranslation("userLeft", {
              user: participant?.identity as string,
            })
          );
        }
      }
    );
    room.on(PROCONF_EVENTS.PARTICIPANT_ROLE_CHANGED, (event) => {
      console.log("ProCONF service:::Participant role changed:", event);
      this.updateParticipantInStore(event.participant);
      if (event.isLocal) {
        //this.updateLocalParticipantInStore(event.participant);
        this.updateParticipantInStore(event.participant);
      }
    });
    room.on(
      PROCONF_EVENTS.DISCONNECTED,
      async (room: Room, error: any, reason: any) => {
        console.log("ProCONF service:::Disconnected:", reason, error);
        // Clear session
        if (reason !== "Room left") {
          // Warning notification only when meeting was auto ended/ended by moderator
          setWarningNotification(getTranslation("meetingEndedMsg"));
        }
        this.isCallInProgress = false;
        // Wait for all tracks to stop and then redirect (fix141)
        await this.cleanUp();
        this.redirect();

        // Fix231: If screen selection dialog before selecting and sharing screen is open, then we can reload page to close it
        if (this.screenShareDialogOpen) {
          window.location.reload();
        }
      }
    );
    room.on(PROCONF_EVENTS.DOMINANT_SPEAKER_CHANGED, (participant: any) => {
      console.log("ProCONF service:::DOMINANT_SPEAKER_CHANGED", participant);
    });

    room.on(PROCONF_EVENTS.ENDPOINT_MESSAGE_RECEIVED, (data: any) => {
      console.log("ROOM_EVENTS data: ", data);
      setTranscriptions(data);
    });

    room.on(PROCONF_EVENTS.TRACK_UNPUBLISHED, (track: Track) => {
      console.log("ProCONF service:::TRACK_UNPUBLISHED", track);
      // If a screen sharing participant leaves meeting
      if (track?.name.includes("screen")) {
        store.dispatch({
          type: "SET_REMOTE_SS",
          payload: false,
        });
        this.switchLocalRecordingLayout(false);
      }
    });
    room.on(PROCONF_EVENTS.ROOM_ERROR, (event: any) => {
      console.log("ProCONF service:::ROOM_ERROR max participants:", event);
      // Clear session
    });
    room.on(PROCONF_EVENTS.TRANSCRIPTION_STATUS_CHANGED, (event: any) => {
      console.log("ProCONF service:::TRANSCRIPTION_STATUS_CHANGED", event);
      // Set transcription toggle as on/off here
      const el = document.getElementById(
        "transcript-switch"
      ) as HTMLButtonElement;
      if (el) {
        el?.classList.remove("disabled-loading");
        el.disabled = false;
      }
      store.dispatch({
        type: "SET_TRANSCRIPTION_STATE",
        payload: event,
      });

      if (event) {
        setSuccessNotification(getTranslation("transcriptStarted"));
      } else {
        setSuccessNotification(getTranslation("transcriptStopped"));
      }
    });
    room.on(PROCONF_EVENTS.NOISE_CANCELLATION_ERROR, (event: any) => {
      console.log("ProCONF service::: NOISE_CANCELLATION_ERROR:", event);
      // TODO: Handle error, states etc.
    });
    // Recording events
    room.on(PROCONF_EVENTS.LOCAL_RECORDING_STATE_CHANGED, (data) => {
      console.log(
        "ProCONF service:::LOCAL_RECORDING_STATE_CHANGED recording state:",
        data
      );
      if (data.state === "recording") {
        const { isRemoteSSOn } = store?.getState()?.ProConfReducer;
        const { screenShareStarted } = store?.getState()?.ToolbarReducer;

        setTimeout(() => {
          if (isRemoteSSOn || screenShareStarted) {
            this.switchLocalRecordingLayout(true);
          }
        }, 1200); // TODO: Need some time for the recorder to be ready, handle this on Recorder lib
      }
    });
    room.on(PROCONF_EVENTS.LOCAL_RECORDING_FILE_READY, () => {
      console.debug(
        "ProCONF service:::LOCAL_RECORDING_FILE_READY downloading file"
      );
    });
    room.on(PROCONF_EVENTS.LOCAL_RECORDING_ERROR, (error) => {
      this.handleLocalRecordingErrors(error);
    });

    // Moderator control related events
    room.on(PROCONF_EVENTS.KICKED, async (participant: any) => {
      console.log("ProCONF service:::Kicked:");
      this.isCallInProgress = false;
      await this.cleanUp();
      this.redirect();
      setWarningNotification(
        // @ts-ignore
        getTranslation("evicted")
      );
    });
    room.on(PROCONF_EVENTS.PARTICIPANT_KICKED, (participant: any) => {
      console.log("ProCONF service:::Remote Participant kicked:");
    });

    // Lobby events
    room.on(PROCONF_EVENTS.LOBBY_USER_JOINED, (waitingParticipant: any) => {
      console.log("ProCONF service:::LOBBY_USER_JOINED", waitingParticipant);
      store.dispatch({
        type: "ADD_PARTICIPANT_IN_WAITING_LOBBY",
        payload: waitingParticipant,
      });
      if (store.getState().ParticipantReducer.waitingLobby.length > 1) {
        setInfoNotification(
          // @ts-ignore
          getTranslation("multipleUsersTitleMessage", {
            user: waitingParticipant?.memberName,
            countOfOthers:
              store.getState().ParticipantReducer.waitingLobby.length - 1,
          }),
          getTranslation("waitingMultipleLobbyUser"),
          this.onClickOfViewWaitingParticipant
        );
      } else if (
        store.getState().ParticipantReducer.waitingLobby.length === 1
      ) {
        setInfoNotification(
          // @ts-ignore
          getTranslation("singleUserTitleMessage", {
            user: waitingParticipant?.memberName,
          }),
          // @ts-ignore
          getTranslation("waitingSingleLobbyUser"),
          this.onClickOfViewWaitingParticipant
        );
      }
    });

    room.on(PROCONF_EVENTS.LOBBY_USER_LEFT, (waitingParticipant: any) => {
      console.log("ProCONF service:::LOBBY_USER_LEFT", waitingParticipant);
      store.dispatch({
        type: "REMOVE_PARTICIPANT_FROM_WAITING_LOBBY",
        payload: waitingParticipant,
      });
    });

    // Network events
    // For local participant
    room.on(PROCONF_EVENTS.CONNECTION_INTERRUPTED, (event: any) => {
      // reconnecting
      console.log("ProCONF service:::CONNECTION_INTERRUPTED", event);
      setWarningNotification(getTranslation("reconnecting"));
    });
    room.on(PROCONF_EVENTS.CONNECTION_RESTORED, (event: any) => {
      // online
      console.log("ProCONF service:::CONNECTION_RESTORED", event);
      setSuccessNotification(getTranslation("online"));
    });
    room.on(PROCONF_EVENTS.CONNECTION_FAILED, (event: any) => {
      // disconnected = offline
      console.log("ProCONF service:::CONNECTION_FAILED", event);
    });
  }

  // Handle local track events
  private handleTrackEvents(room: Room) {
    let vidEl = document.getElementById(
      "video-" + room?.localParticipant?.sid
    ) as HTMLVideoElement;
    console.log("ProCONF service:::handleTrackEvents", room);
    room.localParticipant?.on(
      PROCONF_EVENTS.TRACK_PUBLISHED,
      (localTrack: Track) => {
        console.log("ProCONF service:::TRACK_PUBLISHED", localTrack);
        if (localTrack.kind === "video") {
          // Handle video track
          localTrack.on(PROCONF_EVENTS.ENABLED, async (d) => {
            console.log("ProCONF service:::ENABLED", d);
            if (
              localTrack.kind === "video" &&
              localTrack?.getVideoType() === "camera"
            ) {
              const videoEl = await waitForVideoElement(vidEl?.id);
              localTrack.attach(videoEl);
            }
          });
          localTrack.on(PROCONF_EVENTS.DISABLED, (d) => {
            console.log("ProCONF service:::DISABLED", d);
            if (localTrack.kind === "video") {
              localTrack.detach(vidEl);
              if (d.mutingParticipantId) {
                store.dispatch({ type: ON_VIDEO_MUTE_UNMUTE });
                setWarningNotification(
                  getTranslation("moderatorControlMutedVideo")
                );
              }
            }
          });
        }
        if (localTrack.track.kind === "audio") {
          localTrack.on(PROCONF_EVENTS.DISABLED, (d) => {
            if (d.mutingParticipantId) {
              store.dispatch({ type: ON_MIC_MUTE_UNMUTE });
              setWarningNotification(
                getTranslation("moderatorControlMutedAudio")
              );
            }
          });
        }
        if (
          localTrack?.getVideoType() === "window" ||
          localTrack?.getVideoType() === "desktop"
        ) {
          this.switchLocalRecordingLayout(true);
        }
      }
    );
  }

  // Store already existing remote participants
  private handleRemoteParticipants(room: Room) {
    const remoteParticipants = Array.from(
      room?.participants?.values()
    ) as Participant[];
    remoteParticipants?.forEach((participant: Participant) => {
      console.log("Existing Remote participants: ", participant);
      this.bindParticipantEvents(participant);
      const isParticipantExist = store
        .getState()
        ?.ParticipantReducer.participants.some(
          (p: Participant) => p.sid === participant.sid
        );
      if (
        !isParticipantExist &&
        participant.sid !== room?.localParticipant?.sid
      ) {
        store.dispatch({
          type: "ADD_PARTICIPANT",
          payload: {
            ...participant,
            tracks: participant.tracks
              ? Array.from(participant?.tracks?.values())
              : [],
            audioTracks: participant.audioTracks
              ? Array.from(participant?.audioTracks?.values())
              : [],
            videoTracks: participant.videoTracks
              ? Array.from(participant?.videoTracks?.values())
              : [],
            isLocal: false,
          },
        });
      }
    });
  }

  // Bind remote participant events
  private bindParticipantEvents(participant: Participant) {
    console.log("ProCONFservice:::bindParticipantEvents", participant);
    participant.on(PROCONF_EVENTS.TRACK_SUBSCRIBED, (track: Track) => {
      console.log("ProCONFservice:::TRACK_SUBSCRIBED", participant, track);
      this.bindTrackEvents(participant, track);

      store.dispatch({
        type: "UPDATE_PARTICIPANT",
        payload: {
          ...participant,
          tracks: Array.from(participant?.tracks?.values()),
          audioTracks: Array.from(participant?.audioTracks?.values()),
          videoTracks: Array.from(participant?.videoTracks?.values()),
          isLocal: false,
        },
      });
      console.log(
        "Setting sinkid of newly joined remote Participant: ",
        participant.sid
      );
      if (
        document.getElementById("video-" + participant.sid) &&
        track?.getVideoType() !== "desktop"
      ) {
        this.setSinkId(document.getElementById("video-" + participant.sid));
      }

      // If screen track is received
      if (track?.kind === "video") {
        console.log("Before 2 secs", track?.getVideoType(), track);
        // Added delay as object was not getting updated for screentrack (fix #162)
        setTimeout(async () => {
          console.log("After 2 secs", track?.getVideoType(), track);
          if (track?.getVideoType() === "desktop" && track?.isEnabled) {
            store.dispatch({
              type: "SET_SCREEN_SHARING_PARTICIPANT",
              payload: {
                ...participant,
                tracks: Array.from(participant?.tracks?.values()),
                audioTracks: Array.from(participant?.audioTracks?.values()),
                videoTracks: Array.from(participant?.videoTracks?.values()),
                isLocal: false,
              },
            });
            store.dispatch({
              type: "SET_REMOTE_SS",
              payload: true,
            });
            this.switchLocalRecordingLayout(true);

            setTimeout(() => {
              track?.attach(
                document.getElementById(
                  `screen-${participant?.sid}`
                ) as HTMLVideoElement
              );
            }, 1000);
          } else {
            console.log(
              "Attaching remote track",
              document.getElementById("video-" + participant?.sid)
            );
            if (track?.isEnabled) {
              await waitForVideoElement("video-" + participant?.sid);
              track?.attach(
                document.getElementById(
                  "video-" + participant?.sid
                ) as HTMLVideoElement
              );
            }
          }
        }, 2000);
      }

      if (track.kind === "audio") {
        track?.attach();
      }
    });
    participant.on(PROCONF_EVENTS.TRACK_UNSUBSCRIBED, (track: Track) => {
      console.log("ProCONFservice:::TRACK_UNSUBSCRIBED", participant, track);
      store.dispatch({
        type: "UPDATE_PARTICIPANT",
        payload: {
          ...participant,
          tracks: Array.from(participant?.tracks?.values()),
          audioTracks: Array.from(participant?.audioTracks?.values()),
          videoTracks: Array.from(participant?.videoTracks?.values()),
          isLocal: false,
        },
      });
      setTimeout(() => {
        if (track.kind === "video") {
          if (track?.getVideoType() === "desktop") {
            store.dispatch({
              type: "SET_REMOTE_SS",
              payload: false,
            });
            store.dispatch({
              type: "REMOVE_SCREEN_SHARING_PARTICIPANT",
              payload: participant?.sid,
            });
          }
        }
      }, 2000);

      if (track.kind === "audio") {
        // track.detach();
      }
    });
    participant.on(PROCONF_EVENTS.TRACK_UNPUBLISHED, (track: Track) => {
      console.log("ProCONFservice:::TRACK_UNPUBLISHED", participant, track);
    });
    participant.on(PROCONF_EVENTS.TRACK_PUBLISHED, (track: Track) => {
      console.log("ProCONFservice:::TRACK_PUBLISHED", participant, track);
    });
  }

  // Remote track events
  private bindTrackEvents(participant: Participant, track: Track) {
    console.log("ProCONF service:::bindTrackEvents", participant, track);
    track.on(PROCONF_EVENTS.ENABLED, async (data: Track) => {
      console.log("ProCONF service:::track enabled", data);
      this.updateParticipantInStore(participant);
      if (track?.kind === "video") {
        // Handle remote video track enabled
        if (participant?.sid !== this.localParticipant?.sid) {
          if (track?.getVideoType() === "camera" && track?.isEnabled) {
            const videoEl = await waitForVideoElement(
              "video-" + participant?.sid
            );
            if (videoEl?.paused) {
              track?.attach(videoEl);
            }
            this.switchLocalRecordingLayout(true);
          }
        }
      }
      if (track.kind === "audio") {
        // Handle audio track enabled
      }
    });
    track.on(PROCONF_EVENTS.DISABLED, (data: Track) => {
      console.log("ProCONF service:::Track Disabled event", data);
      this.updateParticipantInStore(participant);
      if (track.kind === "video") {
        setTimeout(() => {
          // Detach remote track
          if (participant?.sid !== this.localParticipant?.sid) {
            track.detach();
            if (track?.getVideoType() === "desktop") {
              store.dispatch({
                type: "SET_REMOTE_SS",
                payload: false,
              });
              store.dispatch({
                type: "REMOVE_SCREEN_SHARING_PARTICIPANT",
                payload: participant?.sid,
              });
              this.switchLocalRecordingLayout(false);
            }
          }
        }, 2000); ///////ppppp check
      }
      if (track.kind === "audio") {
        // Handle audio track disabled
      }
    });
    track.on("screenShareStopped", (data: Track) => {
      console.log("screenShareStopped event received...", data);
    });
  }

  // Create tracks from the selected audio and video devices(used when user refreshes app while in call)
  /*public async createTracksAndJoin(
    roomOptions: any,
    startMeeting: boolean = true,
    isRejoin = false
  ) {
    const micId = store.getState()?.ToolbarReducer?.selectedMic?.deviceId;
    const cameraId = store.getState()?.ToolbarReducer?.selectedCamera?.deviceId;
    const micmute = store.getState()?.ToolbarReducer?.audioMute;
    const videomute = store.getState()?.ToolbarReducer?.videoMute;
    if (micId && cameraId) {
      await this.createTracks(
        {
          micDeviceId: micId,
          cameraDeviceId: cameraId,
        },
        true,
        true
      ).then(async (tracks) => {
        this.tracks = tracks;
        if (micmute) {
          tracks.filter((t: Track) => t.kind === "audio")[0]?.disable();
        }
        if (videomute) {
          tracks.filter((t: Track) => t.kind === "video")[0]?.disable();
        }
        if (startMeeting) {
          this.room = await ProConfService.proConfManager?.startMeeting(
            roomOptions,
            tracks
          );
        } else {
          this.room = await ProConfService.proConfManager?.joinMeeting(
            roomOptions,
            tracks,
            isRejoin
          );
        }
      });
    }
  }*/

  // Mute audio
  public async muteAudio() {
    const audiotrack = this.tracks?.find(
      (track: Track) => track?.kind === "audio"
    );
    await audiotrack?.disable();
  }

  // Unmute audio
  public async unmuteAudio() {
    const audiotrack = this.tracks?.find(
      (track: Track) => track?.kind === "audio"
    );
    if (audiotrack) {
      try {
        await audiotrack?.enable();
      } catch (e: any) {
        console.error(e);
        setErrorNotification(e?.message);
      }
    } else {
      console.log("No audio track found. Creating fresh track.");
      const localParticipant =
        store.getState()?.ParticipantReducer?.localParticipant;
      const audioTrack = await this.createAndPublishTrack("audio");
      store.dispatch({
        type: "UPDATE_PARTICIPANT",
        payload: {
          ...localParticipant,
          tracks: [...localParticipant?.tracks, audioTrack],
          audioTracks: [...localParticipant?.audioTracks, audioTrack],
        },
      });
    }
  }

  // Mute video
  public async muteVideo() {
    const videotrack = this.tracks?.find(
      (track: Track) => track.kind === "video"
    );
    await videotrack?.disable();
  }

  // Unmute video
  public async unmuteVideo() {
    const videotrack = this.tracks.find(
      (track: Track) => track.kind === "video"
    );
    if (videotrack) {
      try {
        await videotrack?.enable();
      } catch (e: any) {
        console.error(e);
        setErrorNotification(e?.message);
      }
    } else {
      console.log("No video track found. Creating fresh track.");
      const videoTrack = await this.createAndPublishTrack("video");
      const localParticipant =
        store.getState()?.ParticipantReducer?.localParticipant;
      store.dispatch({
        type: "UPDATE_PARTICIPANT",
        payload: {
          ...localParticipant,
          tracks: [...localParticipant?.tracks, videoTrack],
          videoTracks: [...localParticipant?.videoTracks, videoTrack],
        },
      });
      await waitForVideoElement("video-" + localParticipant?.sid);
      videoTrack?.attach(
        document.getElementById(
          "video-" + localParticipant?.sid
        ) as HTMLVideoElement
      );
    }
  }

  // Leave meeting
  public async leaveCall() {
    console.log("Leaving room:", this.room);
    this.isCallInProgress = false;
    this.room?.disconnect();
    await this.cleanUp();
    this.redirect();
  }

  // End meeting for all participants by moderator
  public async endMeeting() {
    console.log("End meeting for all participants by moderator:");
    this.isCallInProgress = false;
    if (this.room) {
      this.room?.end();
    }
    // await this.cleanUp();
    // this.redirect();
  }

  // Get meeting list
  public async getMeetingList() {
    return await ProConfService.proConfManager?.getMeetingList();
  }

  // Get call summary
  public async viewCallSummary(roomId: string) {
    console.log("viewCallSummary sdk: ", roomId);
    return await ProConfService.proConfManager?.getCallSummary(roomId);
  }

  // Get call catchup
  public async getCallCatchUp(roomId: string) {
    console.log("Call catchup sdk: ", roomId);
    return await ProConfService.proConfManager?.getCallCatchUp(roomId);
  }

  // Get call status
  public async getCallStatus(roomId: string) {
    console.log("Call status: roomId", roomId);
    return await ProConfService.proConfManager?.getCallStatus(roomId);
  }

  // Store local participant in reducer
  public async setLocalParticipantInStore(
    tracksObj: any,
    audioTracks: Track[],
    videoTracks: Track[],
    update?: boolean
  ) {
    if (update) {
      store.dispatch({
        type: "UPDATE_PARTICIPANT",
        payload: {
          ...this.localParticipant,
          tracks: tracksObj,
          audioTracks: audioTracks,
          videoTracks: videoTracks,
        },
      });
    } else {
      store.dispatch({
        type: "SET_LOCAL_PARTICIPANT",
        payload: {
          ...this.localParticipant,
          tracks: tracksObj,
          audioTracks: audioTracks,
          videoTracks: videoTracks,
        },
      });
      store.dispatch({
        type: "ADD_PARTICIPANT",
        payload: {
          ...this.localParticipant,
          isLocal: true,
          tracks: tracksObj,
          audioTracks: audioTracks,
          videoTracks: videoTracks,
        },
      });
    }
  }

  // Create and publish local audio/video track to room.
  public async createAndPublishTrack(type: string) {
    let obj = {},
      audioFlag = false,
      videoFlag = false;
    if (type === "video") {
      const cameraId =
        store.getState()?.ToolbarReducer?.selectedCamera?.deviceId;
      obj = { cameraDeviceId: cameraId };
      audioFlag = false;
      videoFlag = true;
    }
    if (type === "audio") {
      const micId = store.getState()?.ToolbarReducer?.selectedMic?.deviceId;
      obj = { micDeviceId: micId };
      audioFlag = true;
      videoFlag = false;
    }
    const tracksArray = await this.createTracks(obj, audioFlag, videoFlag);
    const track = tracksArray.find((track: any) => track.kind === type);
    const localTrack = track
      ? await this.room?.localParticipant?.publishTrack(track)
      : null;
    return localTrack;
  }

  // Start screen sharing
  public async startScreenShare() {
    if (
      typeof navigator === "undefined" ||
      !navigator.mediaDevices ||
      !navigator.mediaDevices.getDisplayMedia
    ) {
      return Promise.reject(new Error("getDisplayMedia is not supported"));
    }
    // User has clicked on screen share button to open screen selection dialog
    this.screenShareDialogOpen = true;
    try {
      return await navigator.mediaDevices
        .getDisplayMedia({
          video: true,
        })
        .then((stream) => {
          const { isRemoteSSOn } = store?.getState()?.ProConfReducer;
          if (isRemoteSSOn) {
            stream.getTracks().forEach((track) => {
              track.stop();
            });
            this.screenShareDialogOpen = false;
            return Promise.reject(new Error("Remote screen sharing is on"));
          }
          return ProConfService.proConfManager
            ?.createTrackFromMediaStreamTrack(stream.getVideoTracks()[0])
            .then((tracks) => {
              this.screenShareDialogOpen = false;
              const screenTrack = tracks[0];
              this.screenTrack = screenTrack;

              // Publish the screen track.
              this.room?.localParticipant.publishTrack(screenTrack);

              stream.getVideoTracks()[0].addEventListener("ended", async () => {
                // We come here only if screen share is stopped using browser's button
                if (screenTrack?.getVideoType() === "desktop") {
                  console.log("Screensharing has ended 'ended' event");
                  store.dispatch({ type: ON_SCREEN_SHARE_TOGGLE });
                  await this.localParticipant?.unpublishTrack(screenTrack);
                  this.screenTrack = null;
                  this.screenShareDialogOpen = false;
                  this.switchLocalRecordingLayout(false);
                }
              });
            })
            .catch((error) => {
              console.log("in 1st error: ", error);
              onScreenSharing(true);
              return Promise.reject(new Error("Error in getDisplayMedia"));
            });
        });
    } catch (error: any) {
      console.log("in 2nd error: ", error);
      onScreenSharing(true);
      console.error("Error in getDisplayMedia", error);
    }
  }

  // Stop screen share button click
  stopScreenShare = async (st?: Track) => {
    console.log("Stopscreen button click", this.screenTrack);
    const track = st || this.screenTrack;
    if (track && track.sid) {
      await this.localParticipant?.unpublishTrack(track);
      this.screenShareDialogOpen = false;
    }
    this.screenTrack = null;
  };

  // Toggle Noise Cancellation
  public toggleNoiseCancellation(enable: boolean) {
    try {
      this.room?.setNoiseSuppression(enable);
    } catch (error) {
      console.error(
        "ProConfService::: error in toggleNoiseCancellation:",
        error
      );
      // TODO: handle error, reset noise cancellation state etc.
    }
  }

  // Attach local tracks to DOM
  async attachLocalTracks(room: Room | null | undefined = this.room) {
    const localP = store.getState().ParticipantReducer.localParticipant;
    let localvideoTrack = localP.videoTracks[0];
    if (
      localvideoTrack?.isEnabled &&
      localvideoTrack?.getVideoType() === "camera"
    ) {
      await waitForVideoElement("video-" + this.room?.localParticipant?.sid);
      localvideoTrack?.attach(
        document.getElementById(
          "video-" + room?.localParticipant?.sid
        ) as HTMLVideoElement
      );
    }
  }

  // Update participant tracks in store
  updateParticipantInStore(
    participant: Participant,
    localAudioTracks?: Track[],
    localVideoTracks?: Track[]
  ) {
    let tracks, audioTracks, videoTracks;
    if (localAudioTracks) {
      tracks = [...participant?.tracks, ...localAudioTracks];
      audioTracks = [...participant?.audioTracks, ...localAudioTracks];
      videoTracks = participant?.videoTracks;
    } else if (localVideoTracks) {
      tracks = [...participant?.tracks, ...localVideoTracks];
      videoTracks = [...participant?.videoTracks, ...localVideoTracks];
      audioTracks = participant?.audioTracks;
    } else {
      tracks = participant?.tracks
        ? Array.from(participant?.tracks?.values())
        : [];
      audioTracks = participant?.audioTracks
        ? Array.from(participant?.audioTracks?.values())
        : [];
      videoTracks = participant?.videoTracks
        ? Array.from(participant?.videoTracks?.values())
        : [];
    }
    store.dispatch({
      type: "UPDATE_PARTICIPANT",
      payload: {
        ...participant,
        tracks: tracks,
        audioTracks: audioTracks,
        videoTracks: videoTracks,
        isModerator: participant?.isModerator,
      },
    });
  }

  // // Update local participant in store
  // updateLocalParticipantInStore(participant: Participant) {
  //   store.dispatch({
  //     type: "SET_LOCAL_PARTICIPANT",
  //     payload: {
  //       ...this.localParticipant,
  //       tracks: Array.from(this.localParticipant?.tracks?.values()),
  //       audioTracks: Array.from(this.localParticipant?.audioTracks?.values()),
  //       videoTracks: Array.from(this.localParticipant?.videoTracks?.values()),
  //       isModerator: participant?.isModerator,
  //     },
  //   });
  // }

  /*// Store local participant in store
  storeLocalParticipant() {
    store.dispatch({
      type: "SET_LOCAL_PARTICIPANT",
      payload: {
        ...this.localParticipant,
        tracks: Array.from(this.localParticipant?.tracks?.values()),
        audioTracks: Array.from(this.localParticipant?.audioTracks?.values()),
        videoTracks: Array.from(this.localParticipant?.videoTracks?.values()),
      },
    });
    store.dispatch({
      type: "ADD_PARTICIPANT",
      payload: {
        ...this.localParticipant,
        isLocal: true,
        tracks: this.localParticipant?.tracks
          ? Array.from(this.localParticipant?.tracks?.values())
          : [],
        audioTracks: this.localParticipant?.audioTracks
          ? Array.from(this.localParticipant?.audioTracks?.values())
          : [],
        videoTracks: this.localParticipant?.videoTracks
          ? Array.from(this.localParticipant?.videoTracks?.values())
          : [],
      },
    });
  }*/

  // Set sink id of remote audio element
  setSinkId(element?: any, speaker?: any) {
    try {
      const selectedSpeaker =
        speaker || store.getState().ToolbarReducer?.selectedSpeaker;
      console.log("Setting sinkid of: ", selectedSpeaker);
      if (selectedSpeaker && selectedSpeaker?.deviceId) {
        if (typeof element?.sinkId !== "undefined") {
          applyAudioOutputDeviceSelection(selectedSpeaker?.deviceId, element);
        }
      }
    } catch (error) {
      console.error("Error in setting sink id of remote audio element", error);
    }
  }

  // Stop all Local tracks
  async clearTracks() {
    if (this.tracks) {
      this.tracks.forEach(async (track: Track) => {
        await track?.stop();
      });
    }
    if (this.screenTrack && this.screenTrack?.sid) {
      await this.screenTrack?.stop();
    }
  }

  // Reset everything after call gets over
  async cleanUp() {
    console.log("Cleanup function called");
    await this.clearTracks();
    this.room = null;
    this.localParticipant = null;
    this.tracks = [];
    store.dispatch({ type: CALL_IN_PROGRESS, payload: false });
    store.dispatch({ type: CLEAR_PARTICIPANTS });
    store.dispatch({ type: CLEAR_ROOM });
    store.dispatch({ type: RESET_TOOLBAR_STATE });
    store.dispatch({ type: SET_REMOTE_SS, payload: false });
    store.dispatch({ type: CLEAR_INCALL_REDUCER });
  }

  // Redirect to landing page/login pg
  redirect() {
    console.log("Room ended...redirecting");
    setTimeout(() => store.dispatch({ type: SET_ROOM_ENDED, payload: true }));
  }

  // Create default tracks without device selection
  // async createDefaultTracks() {
  //   this.tracks = await ProConfService.proConfManager?.createLocalTracks({
  //     devices: ["audio", "video"],
  //   });
  // }

  /*
    CLIENT SIDE RECORDING
  */
  /**
   * Local recording error handling
   * @param event
   * @returns
   */
  // TODO: Handle this
  private handleLocalRecordingErrors(error: any) {
    console.error(
      "ProCONF service:::Error in local recording:",
      error,
      error.code,
      error.message
    );

    if (!error || !error.code) {
      return;
    }

    switch (error.code) {
      case ProConfErrorCode.ERROR_IN_LOCAL_RECORDING_LAYOUT_SWITCHING:
        console.log(
          ProConfErrorMessages[
            ProConfErrorCode.ERROR_IN_LOCAL_RECORDING_LAYOUT_SWITCHING
          ]
        );
        break;
      case ProConfErrorCode.ERROR_IN_SAVE_LOCAL_RECORDING:
        setErrorNotification(
          ProConfErrorMessages[ProConfErrorCode.ERROR_IN_SAVE_LOCAL_RECORDING]
        );
        break;
      case ProConfErrorCode.ERROR_IN_PAUSE_LOCAL_RECORDING:
        setErrorNotification(
          ProConfErrorMessages[ProConfErrorCode.ERROR_IN_PAUSE_LOCAL_RECORDING]
        );
        break;
      case ProConfErrorCode.ERROR_IN_RESUME_LOCAL_RECORDING:
        setErrorNotification(
          ProConfErrorMessages[ProConfErrorCode.ERROR_IN_RESUME_LOCAL_RECORDING]
        );
        break;
      case ProConfErrorCode.ERROR_IN_LOCAL_RECORDING:
        setErrorNotification(
          ProConfErrorMessages[ProConfErrorCode.ERROR_IN_LOCAL_RECORDING]
        );
        break;
      case ProConfErrorCode.ERROR_IN_START_LOCAL_RECORDING:
        // Reset button
        store.dispatch({ type: RESET_RECORDING_STATE });
        setErrorNotification(
          ProConfErrorMessages[ProConfErrorCode.ERROR_IN_START_LOCAL_RECORDING]
        );
        break;
      case ProConfErrorCode.ERROR_IN_STOP_LOCAL_RECORDING:
        setErrorNotification(
          ProConfErrorMessages[ProConfErrorCode.ERROR_IN_STOP_LOCAL_RECORDING]
        );
        break;
      default:
        break;
    }
    setTimeout(() => {
      store.dispatch({ type: CLEAR_NOTIFICATIONS });
    }, 2000);
  }

  /**
   * Switch local recording layout
   * @param isScreenShare - true to switch to screen share
   */
  public switchLocalRecordingLayout(isScreenShare: boolean) {
    try {
      this.room?.switchLocalRecordingLayout({
        layout: isScreenShare ? "screenShare" : "grid",
      });
    } catch (error) {
      console.warn(
        "ProCONF service:::Error in switching local recording layout:",
        error
      );
    }
  }

  /**
   * On local recording toggle start/stop
   * @param startRec - true to start, false to stop
   */
  public toggleLocalRecording(startRec: boolean) {
    try {
      if (startRec) {
        this.room?.startLocalRecording();
        setSuccessNotification(getTranslation("recStarted"));
      } else {
        this.room?.stopLocalRecording();
        setSuccessNotification(getTranslation("recStopped"));
      }
    } catch (error) {
      store.dispatch({ type: RESET_RECORDING_STATE });
      // TODO: Handle this
      console.warn(
        "ProCONF service:::Error in",
        startRec ? " starting" : "stopping",
        "local recording:",
        error
      );
      setErrorNotification(getTranslation("recFailed"));
    }
  }

  /**
   * On local recording pause/resume toggle
   * @param pause - true to pause, false to resume
   * currently not used in this app
   */
  public togglePauseLocalRecording(pause: boolean) {
    pause
      ? this.room?.pauseLocalRecording()
      : this.room?.resumeLocalRecording();
  }

  /*
    MODERATOR CONTROLS
  */
  // Mute one or all remote participant's audio/video
  muteParticipant(id: string | Array<string>, mediaType: "audio" | "video") {
    this.room?.muteParticipant(id, mediaType);
  }

  // Evict remote participant
  evictParticipant(id: string, reason?: string) {
    console.info("Evict participant", id, reason);
    if (reason) {
      this.room?.kickParticipant(id, reason);
    } else {
      this.room?.kickParticipant(id);
    }
  }

  // Grant Admin rights to a remote participant
  grantAdminRights(id: string) {
    this.room?.grantAdminRights(id);
  }

  /*
    LOBBY/WAITING ROOM HANDLING
  */
  // Admit from lobby
  admitParticipant(id: string | Array<string>) {
    this.room?.admitFromLobby(id);
  }

  // Reject from lobby
  rejectParticipant(id: string) {
    this.room?.rejectFromLobby(id);
  }

  /**
   * Media devices management
   */
  handleMediaEvents() {
    const deviceManager = ProConfService.deviceManager;
    // Handle browser permissions
    deviceManager?.on(PROCONF_EVENTS.PERMISSIONS_CHANGED, async (e: any) => {
      console.log("ProCONF service:::Permissions changed:", e);
      if (e.type === "audio") {
        if (e.permission) {
          store.dispatch({ type: SET_AUDIO_PERMISSION, payload: true });
          if (this.isCallInProgress) {
            const audioMute = store.getState()?.ToolbarReducer?.audioMute;
            if (!audioMute) {
              // Mic permission is enabled
              const existingAudioTrack = this.tracks.find(
                (track: any) => track.kind === "audio"
              );
              if (existingAudioTrack) {
                await this.unmuteAudio();
              } else {
                await this.getAudioInputDevices();
                await this.getAudioOutputDevices();
                store.dispatch({
                  type: SET_SELECTED_MIC,
                  payload:
                    store.getState()?.InCallReducer?.audioInputDevices[0],
                });
                // Create fresh tracks if they don't exist
                let audioTracks = [];
                const localP =
                  store.getState()?.ParticipantReducer.localParticipant;
                const localAudioTrack = await this.createAndPublishTrack(
                  "audio"
                );
                audioTracks.push(localAudioTrack);
                this.updateParticipantInStore(localP, audioTracks);
              }
            }
          } else {
            // Meeting preview screen
            await this.getAudioInputDevices();
            await this.getAudioOutputDevices();
          }
        } else {
          store.dispatch({ type: SET_AUDIO_PERMISSION, payload: false });
          if (this.isCallInProgress) {
            if (this.tracks?.length > 0) {
              this.tracks.forEach(async (track: any) => {
                if (track.kind === "audio") {
                  await this.muteAudio();
                }
              });
            }
          } else {
            store.dispatch({ type: SET_AUDIO_INPUT_DEVICES, payload: [] });
            store.dispatch({ type: SET_AUDIO_OUTPUT_DEVICES, payload: [] });
            this.tracks = this.tracks.filter((t: any) => t.kind !== "audio");
          }
        }
      }
      if (e.type === "video") {
        if (e.permission) {
          store.dispatch({ type: SET_VIDEO_PERMISSION, payload: true });
          if (this.isCallInProgress) {
            const videoMute = store.getState()?.ToolbarReducer?.videoMute;
            if (!videoMute) {
              // Camera permission is enabled
              const existingVideoTrack = this.tracks.find(
                (track: any) => track.kind === "video"
              );
              if (existingVideoTrack) {
                await this.unmuteVideo();
              } else {
                await this.getVideoDevices();
                store.dispatch({
                  type: SET_SELECTED_CAMERA,
                  payload: store.getState()?.InCallReducer?.videoDevices[0],
                });
                // Create fresh tracks if they don't exist
                let videoTracks = [];
                const localP =
                  store.getState()?.ParticipantReducer.localParticipant;
                const localvideoTrack = await this.createAndPublishTrack(
                  "video"
                );
                videoTracks.push(localvideoTrack);
                this.updateParticipantInStore(localP, undefined, videoTracks);
                await waitForVideoElement("video-" + localP?.sid);
                localvideoTrack?.attach(
                  document.getElementById("video-" + localP?.sid)
                );
              }
            }
          } else {
            await this.getVideoDevices();
          }
        } else {
          store.dispatch({ type: SET_VIDEO_PERMISSION, payload: false });
          if (this.isCallInProgress) {
            if (this.tracks?.length > 0) {
              this.tracks.forEach(async (track: any) => {
                if (track.kind === "video") {
                  await this.muteVideo();
                }
              });
            }
          } else {
            store.dispatch({ type: SET_VIDEO_DEVICES, payload: [] });
            this.tracks = this.tracks.filter((t: any) => t.kind !== "video");
          }
        }
      }
    });

    // Handle device removal/addition
    deviceManager?.on(
      PROCONF_EVENTS.DEVICE_LIST_CHANGED,
      async (deviceList: any) => {
        console.log("ProCONF service:::Device list changed:", deviceList);
        // Microphones
        if (
          deviceList?.added?.audioInput?.length > 0 ||
          deviceList?.removed?.audioInput?.length > 0
        ) {
          try {
            await this.getAudioInputDevices();
          } catch (e) {
            console.error("Error fetching audio input devices", e);
          }
          if (
            deviceList?.removed?.audioInput?.length > 0 &&
            this.isCallInProgress
          ) {
            // const removedMics = deviceList?.removed?.audioInput;
            // const selectedMicId =
            //   store.getState()?.ToolbarReducer?.selectedMic?.deviceId;
            // const isRemoved = removedMics.find(
            //   (m: any) => m.deviceId === selectedMicId
            // );

            // if (isRemoved) {
            const audioTrack = this.tracks.filter(
              (t: Track) => t.kind === "audio"
            );
            console.log("Restarting mic:", audioTrack);
            await audioTrack[0]?.restart({
              deviceId: {
                exact:
                  store.getState()?.InCallReducer?.audioInputDevices[0]
                    ?.deviceId,
              },
            });
            store.dispatch({
              type: SET_SELECTED_MIC,
              payload: store.getState()?.InCallReducer?.audioInputDevices[0],
            });
            // }
          }
        }
        // Speakers
        if (
          deviceList?.added?.audioOutput?.length > 0 ||
          deviceList?.removed?.audioOutput.length > 0
        ) {
          try {
            await this.getAudioOutputDevices();
          } catch (e) {
            console.error("Error fetching audio output devices", e);
          }
          if (
            deviceList?.removed?.audioOutput?.length > 0 &&
            this.isCallInProgress
          ) {
            // const removedSpeakers = deviceList?.removed?.audioOutput;
            // const selectedSpeakerId =
            //   store.getState()?.ToolbarReducer?.selectedSpeaker?.deviceId;
            // const isRemoved = removedSpeakers.find(
            //   (m: any) => m.deviceId === selectedSpeakerId
            // );
            // if (isRemoved) {
            console.log("Restarting speaker");
            this.setAudioOutputDevice(
              store.getState()?.InCallReducer?.audioOutputDevices[0].deviceId
            );
            store.dispatch({
              type: SET_SELECTED_SPEAKER,
              payload: store.getState()?.InCallReducer?.audioInputDevices[0],
            });
            //}
          }
        }
        // Camera
        if (
          deviceList?.added?.camera?.length > 0 ||
          deviceList?.removed?.camera?.length > 0
        ) {
          try {
            await this.getVideoDevices();
          } catch (e) {
            console.error("Error fetching video devices", e);
          }
          if (
            deviceList?.removed?.camera?.length > 0 &&
            this.isCallInProgress
          ) {
            const removedCameras = deviceList?.removed?.camera;
            const selectedCameraId =
              store.getState()?.ToolbarReducer?.selectedCamera?.deviceId;
            const isRemoved = removedCameras.find(
              (m: any) => m.deviceId === selectedCameraId
            );
            if (isRemoved) {
              const videoTrack = this.tracks.filter(
                (t: Track) => t.kind === "video"
              );
              console.log("Restarting camera");
              await videoTrack[0]?.restart({
                deviceId: {
                  exact:
                    store.getState()?.InCallReducer?.videoDevices[0]?.deviceId,
                },
              });
              store.dispatch({
                type: SET_SELECTED_CAMERA,
                payload: store.getState()?.InCallReducer?.videoDevices[0],
              });
              const videoEl = await waitForVideoElement(
                "video-" + this.localParticipant?.sid
              );
              videoTrack[0]?.attach(videoEl);
            }
          } else if (
            store.getState()?.InCallReducer?.videoDevices.length === 1 &&
            deviceList?.added?.camera?.length > 0 &&
            this.isCallInProgress
          ) {
            // case where there is only an external webcam attached to mac or other desktop.
            const videoTrack = this.tracks.filter(
              (t: Track) => t.kind === "video"
            );
            console.log("Restarting camera", videoTrack);
            await videoTrack[0]?.restart({
              deviceId: {
                exact:
                  store.getState()?.InCallReducer?.videoDevices[0]?.deviceId,
              },
            });
            store.dispatch({
              type: SET_SELECTED_CAMERA,
              payload: store.getState()?.InCallReducer?.videoDevices[0],
            });
            const videoEl = await waitForVideoElement(
              "video-" + this.localParticipant?.sid
            );
            videoTrack[0]?.attach(videoEl);
          }
        }
      }
    );
  }

  async isBrowserPermissionAllowed(type: string) {
    const permission =
      await ProConfService.deviceManager?.isBrowserPermissionAllowed(
        type,
        true
      );
    if (type === "audio") {
      if (permission)
        store.dispatch({ type: "SET_AUDIO_PERMISSION", payload: true });
      else store.dispatch({ type: "SET_AUDIO_PERMISSION", payload: false });
    }
    if (type === "video") {
      if (permission)
        store.dispatch({ type: "SET_VIDEO_PERMISSION", payload: true });
      else store.dispatch({ type: "SET_VIDEO_PERMISSION", payload: false });
    }
    console.log(
      "ProCONF service:::isBrowserPermissionAllowed:",
      type,
      permission
    );
    return permission;
  }

  async getAudioInputDevices() {
    const devices = await ProConfService.deviceManager?.getAudioInputDevices(
      true
    );
    store.dispatch({ type: SET_AUDIO_INPUT_DEVICES, payload: devices });
  }

  async getAudioOutputDevices() {
    const devices = await ProConfService.deviceManager?.getAudioOutputDevices(
      true
    );
    store.dispatch({ type: SET_AUDIO_OUTPUT_DEVICES, payload: devices });
  }

  async getVideoDevices() {
    const devices = await ProConfService.deviceManager?.getVideoInputDevices(
      true
    );
    store.dispatch({ type: SET_VIDEO_DEVICES, payload: devices });
  }

  // Set selected speaker device
  async setAudioOutputDevice(deviceId: any) {
    try {
      return await ProConfService.deviceManager?.setAndApplyAudioOutput(
        deviceId
      );
    } catch (error: any) {
      console.error(error);
    }
  }

  getSelectedAudioInputDevice() {
    return ProConfService.deviceManager?.getCurrentAudioInput();
  }

  getSelectedAudioOutputDevice() {
    return ProConfService.deviceManager?.getCurrentAudioOutput();
  }

  getSelectedVideoDevice() {
    return ProConfService.deviceManager?.getCurrentVideoInput();
  }

  onClickOfViewWaitingParticipant = () => {
    store.dispatch({ type: ON_PARTICIPANT_PANEL_TOGGLE });
  };
}

const proConfService = new ProConfService();

export default proConfService;
