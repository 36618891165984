import { FunctionComponent, useMemo, type CSSProperties } from "react";
import "./ParticipantActionControls.scss";
import { Participant } from "proconf-web-sdk";

export type ParticipantActionControlsType = {
  className?: string;
  participantActionMenuIcon?: string;
  participantActionLabel?: string;
  menuIcon?: string;
  participantActionLabel1?: string;
  isActive?: boolean;
  participant?: Participant;
  clickHandler?: () => void;

  /** Style props */
  participantActionMenuButtonOpacity?: CSSProperties["opacity"];
};

const ParticipantActionControls: FunctionComponent<
  ParticipantActionControlsType
> = ({
  className = "",
  participantActionMenuIcon,
  participantActionLabel,
  participantActionMenuButtonOpacity,
  menuIcon,
  participantActionLabel1,
  isActive = false,
  participant,
  clickHandler,
}) => {
  const participantActionMenuButtonStyle: CSSProperties = useMemo(() => {
    return {
      opacity: participantActionMenuButtonOpacity,
    };
  }, [participantActionMenuButtonOpacity]);

  return (
    <div className={`participantactioncontrols ${className}`}>
      {isActive && (
        <button className="participantactionmenubutton7" onClick={clickHandler}>
          <img
            className="participantactionmenuicon1"
            alt="FocusMenuIcon"
            src={participantActionMenuIcon}
          />
          <div className="participantactionlabel10">
            {participantActionLabel}
          </div>
        </button>
      )}
      {!isActive && (
        <button
          className="participantactionmenubutton7"
          style={participantActionMenuButtonStyle}
        >
          <img
            className="participantactionmenuicon1"
            alt="ExitFocusMenuIcon"
            src={menuIcon}
          />
          <div className="participantactionlabel10">
            {participantActionLabel1}
          </div>
        </button>
      )}
    </div>
  );
};

export default ParticipantActionControls;
