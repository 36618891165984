import { FunctionComponent, useState } from "react";
import ParticipantActionControls from "./ParticipantActionControls";
import "./ParticipantActionMenu.scss";
import { Participant } from "proconf-web-sdk";
import proConfService from "../../Services/ProConfService";

import { ReactComponent as EvictMenuIcon } from "../../Resources/InCallImages/EvictMenuIcon.svg";
import ExitFocusMenuIcon from "../../Resources/InCallImages/ExitFocusMenuIcon.svg";
import FocusMenuIcon from "../../Resources/InCallImages/FocusMenuIcon.svg";
import ModeratorMenuIcon from "../../Resources/InCallImages/ModeratorMenuIcon.svg";
import MuteParticipantIcon from "../../Resources/InCallImages/MuteParticipantIcon.svg";
import MuteParticipantIcon1 from "../../Resources/InCallImages/MuteParticipantIcon1.svg";
import RemoveModeratorMenuIcon from "../../Resources/InCallImages/RemoveModeratorMenuIcon.svg";
import ConfirmationPopup from "../ConfirmationPopup/ConfirmationPopup";
import PortalPopup from "../PortalPopup/PortalPopup";
import { getTranslation } from "../../Resources/Localization/i18n";

export type ParticipantActionMenu2Type = {
  className?: string;
  onClose?: () => void;
  participant: Participant;
};

const ParticipantActionMenu: FunctionComponent<ParticipantActionMenu2Type> = ({
  className = "",
  participant,
  onClose = () => {},
}) => {
  const [isEvictClicked, setIsEvictClicked] = useState(false);

  const handleMuteRemoteParticipant = (type: "audio" | "video") => {
    console.info("handleMuteRemoteParticipant", participant, type);
    const p = Array.from(proConfService?.room?.participants.values())?.filter(
      (p: any) => p?.sid === participant.sid
    )[0] as Participant;
    const pid = p?.getId();
    if (pid) {
      proConfService?.muteParticipant(pid, type);
    }
    onClose();
  };

  const handleEvictParticipant = (participant: Participant) => {
    const p = Array.from(proConfService?.room?.participants.values())?.filter(
      (p: any) => p?.sid === participant.sid
    )[0] as Participant;
    const pid = p?.getId();
    if (pid) {
      proConfService.evictParticipant(pid);
    }
    setIsEvictClicked(false);
    onClose();
  };

  const handleMakeAModerator = (participant: Participant) => {
    const p = Array.from(proConfService?.room?.participants.values())?.filter(
      (p: any) => p?.sid === participant.sid
    )[0] as Participant;
    const pid = p?.getId();
    if (pid) {
      proConfService.grantAdminRights(pid);
    }
    onClose();
  };

  return (
    <>
      <div className={`participantactionmenu2 ${className}`}>
        {/* <ParticipantActionControls
          participantActionMenuIcon={FocusMenuIcon}
          participantActionLabel={getTranslation("focus")}
          menuIcon={ExitFocusMenuIcon}
          participantActionLabel1={getTranslation("exitFocus")}
          isActive={false}
          participant={participant}
        /> */}
        <ParticipantActionControls
          participantActionMenuIcon={MuteParticipantIcon}
          participantActionLabel={getTranslation("muteParticipant")}
          participantActionMenuButtonOpacity="0.4"
          menuIcon={MuteParticipantIcon}
          participantActionLabel1={getTranslation("muteParticipant")}
          isActive={participant.audioTracks[0]?.isEnabled}
          participant={participant}
          clickHandler={() => handleMuteRemoteParticipant("audio")}
        />
        <ParticipantActionControls
          participantActionMenuIcon={MuteParticipantIcon1}
          participantActionLabel={getTranslation("turnOffVideo")}
          participantActionMenuButtonOpacity="0.4"
          menuIcon={MuteParticipantIcon1}
          participantActionLabel1={getTranslation("turnOffVideo")}
          isActive={participant.videoTracks[0]?.isEnabled}
          participant={participant}
          clickHandler={() => handleMuteRemoteParticipant("video")}
        />
        <ParticipantActionControls
          participantActionMenuIcon={ModeratorMenuIcon}
          participantActionLabel={getTranslation("makeAModerator")}
          participantActionMenuButtonOpacity="0.4"
          menuIcon={RemoveModeratorMenuIcon}
          participantActionLabel1={getTranslation("makeAModerator")}
          isActive={!participant.isModerator}
          participant={participant}
          clickHandler={() => handleMakeAModerator(participant)}
        />
        <button
          className="participantactionmenubutton6"
          onClick={() => setIsEvictClicked(true)}
        >
          <EvictMenuIcon className="menuicon5" />
          <div className="participantactionlabel9">
            {getTranslation("evictParticipant")}
          </div>
        </button>
      </div>
      {isEvictClicked && (
        <PortalPopup overlayColor="rgba(0, 0, 0, 0.2)" placement="Centered">
          <ConfirmationPopup
            confirmationTitle={getTranslation("evictParticipant")}
            confirmationMessage={getTranslation("evictConfirmation")}
            actionName={getTranslation("evict")}
            onCancelClick={() => setIsEvictClicked(false)}
            onActionClick={() => handleEvictParticipant(participant)}
          />
        </PortalPopup>
      )}
    </>
  );
};

export default ParticipantActionMenu;
